// Object - project - education

/**
* 充実した実践教育
**/

.education-content-01 {
    padding-bottom: 100px;
    border-bottom: 1px solid $color-brand;
    .image01 {
        margin: 45px 0 20px;
    }
    .wrapper {
        max-width: 800px;
        margin: 70px auto 0;
        .upper img {
            display: block;
            height: 39px;
            margin-bottom: 10px;
        }
    }
    @include _mq-down(md) {
        padding-bottom: 40px;
        .image01 {
            margin: 25px 0 0px;
        }
        .wrapper {
            margin: 50px auto 0;
            .upper img {
                height: 32px;
                margin-bottom: 10px;
            }
        }
        .flex-item + .flex-item {
            margin-top: 50px;
        }
        .page-text-tit-02.sp-l {
            text-align: justify;
        }
        .page-text-tit-03 {
            font-size: 15px;
            text-align: justify;
        }
    }
}
.education-b-content-02 {
    padding-top: 130px;
    @include _mq-down(md) {
        padding-top: 70px;
    }
}

.education-h-content-02 {
    padding-top: 90px;
    padding-bottom: 100px;
    border-bottom: 1px solid $color-brand;
    .image02 {
        margin: 32px 0 0;
    }
    @include _mq-down(md) {
        padding-top: 32px;
        padding-bottom: 40px;
        .page-text-tit-03 {
            font-size: 15px;
            text-align: justify;
        }
        .image02 {
            margin: 25px 0 0px;
        }
    }
}

.education-point-list {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    li {
        max-width: 279px;
        padding: 0 15px;
        box-sizing: content-box;
        .upper {
            display: flex;
            align-items: center;
            .point {
                display: inline-block;
                color: $color-brand;
                font-size: 16px;
                font-weight: bold;
                line-height: 1.3;
                text-align: center;
                letter-spacing: 0.05em;
                position: relative;
                padding-right: 24px;
                &::after {
                    content: '';
                    width: 20px;
                    height: 53px;
                    background: url("../img/education/education_arrow-r-brand.svg") right center / 20px 53px no-repeat;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                }
            }
            .tit {
                font-size: 18px;
                font-weight: bold;
                line-height: 1.6667;
                letter-spacing: 0.05em;
                margin-left: 11px;
            }
        }
        .image {
            margin: 20px 0 13px;
        }
    }
    @include _mq-up(md) {
        li + li {
            border-left: 1px dashed $color-brand;
        }
    }
    @include _mq-down(md) {
        display: block;
        li {
            max-width: 100%;
            .upper {
                .point {
                    font-size: 14px;
                    &::after {
                        width: 17px;
                        height: 45px;
                    }
                }
                .tit {
                    font-size: 16px;
                    line-height: 1.5;
                }
            }
            .image {
                margin: 15px 0 10px;
            }
        }
        li + li {
            border-top: 1px dashed $color-brand;
            padding-top: 30px;
            margin-top: 30px;
        }
    }
}


