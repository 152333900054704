// Object - project - subject

/**
* 就職実績
**/
.Achievement {
    @include _mq-down(md) {
        .page-text-tit-01 {
            text-align: justify;
        }
    }
}

.achievement-content-data {
    .mt-spacer {
        margin-top: 90px;
    }
    .page-content-heading {
        margin-top: 100px;
        @include _mq-down(md) {
            margin-top: 70px;
        }
    }
}
.achievement-content-support {
    .page-content-heading {
        margin-top: 100px;
        @include _mq-down(md) {
            margin-top: 70px;
        }
    }
}


.achievement-date-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    li {
        width: 290px;
        background: #E6E6E6;
        padding: 20px 0 18px;
    }
    .tit {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-align: center;
    }
    .percent {
        color: $color-brand;
        font-size: 55px;
        font-weight: bold;
        text-align: center;
        margin-top: 5px;
        letter-spacing: 0.05em;
        span {
            font-size: 30px;
        }
    }
    @include _mq-down(md) {
        display: block;
        li {
            width: 320px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: auto;
            margin-left: auto;
            padding: 9px 0 11px;
            & + li {
                margin-top: 10px;
            }
        }
        .tit {
            font-size: 16px;
            margin-top: 2px;
        }
        .percent {
            font-size: 30px;
            margin-top: 0px;
            margin-left: 10px;
            span {
                font-size: 16px;
            }
        }
    }
}

.achievement-check-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
    margin: 55px auto 0;
    @include _mq-down(md) {
        max-width: 100%;
        display: block;
    }
    li {
        width: 325px;
        position: relative;
        border: 1px solid #000;
        padding: 30px 25px 25px;
        @include _mq-up(md) {
            &:nth-of-type(even) {
                margin-left: 50px;
            }
            &:nth-of-type(n+3) {
                margin-top: 62px;
            }
        }
        @include _mq-down(md) {
            width: 335px;
            margin: 55px auto 0;
            padding: 30px 20px 25px;
        }
    }
    .check {
        color: $color-brand;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        width: 150px;
        //height: 45px;
        padding: 7px;
        border: 2px solid $color-brand;
        background: #fff;
        letter-spacing: 0.05em;
        position: absolute;
        top: -22px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .tit {
        color: $color-brand;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.05em;
        line-height: 1.625;
        min-height: 52px;
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .text {
        font-size: 13px;
        line-height: 1.9231;
        letter-spacing: 0.05em;
        text-align: justify;
    }
}

.achievement-content-interview {
    .center {
        text-align: center;
    }
    .page-content-heading {
        margin-top: 120px;
        @include _mq-down(md) {
            margin-top: 70px;
        }
    }
}

.achievement-voice-card {
    display: flex;
    justify-content: space-between;
    @include _mq-up(md) {
        &:nth-of-type(1) {
            margin-top: 50px;
        }
        &:nth-of-type(even) {
            margin-top: 50px;
            flex-direction: row-reverse;
        }
        & + .achievement-voice-card {
            margin-top: 105px;
        }
    }
    .image-area {
        width: 425px;
    }
    .text-area {
        width: 425px;
        .tit {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: 0.05em;
            text-align: justify;
            margin-bottom: 10px;
        }
    }
    @include _mq-down(md) {
        display: block;
        &:nth-of-type(1) {
            margin-top: 30px;
        }
        & + .achievement-voice-card {
            margin-top: 40px;
        }
        .image-area {
            width: 100%;
            margin-bottom: 14px;
        }
        .text-area {
            width: 100%;
            .tit {
                font-size: 15px;
            }
        }

    }
}

.achievement-content-job {
    .mt-spacer {
        margin-top: 25px;
    }
    p {
        text-align: left;
        letter-spacing: 0.1em;
        & + p {
            margin-top: 1.5em;
        }
    }
    .page-content-heading {
        margin-top: 120px;
        @include _mq-down(md) {
            margin-top: 70px;
        }
    }
}

.achievement-content-company {
    .mt-spacer {
        margin-top: 25px;
    }
    strong {
        display: block;
        margin-top: 1.5em;
    }
    .page-content-heading {
        margin-top: 120px;
        @include _mq-down(md) {
            margin-top: 70px;
        }
    }
}
