// Object - project - info

/**
* 学校長　野沢 道生
**/
.Principal {

}

.principal-content {
    .page-content-heading {
        margin-top: 0;
    }
}

.principal-content-profile {
    position: relative;
    padding-bottom: 20px;
    margin-top: 50px;
    .box {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 328px;
        height: auto;
        padding: 27px 30px 30px;
        color: #fff;
        background-color: $color-brand;
        background-image: url(../img/common/bg_st_white30.png);
        background-size: 5px;
        .tit {
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0.06em;
        }
        .name {
            margin-top: 8px;
            font-size: 23px;
            font-weight: bold;
            letter-spacing: 0.1em;
            span {
                font-size: 13px;
                font-weight: normal;
                letter-spacing: 0.07em;
                margin-left: 10px;
            }
        }
        .post {
            font-size: 13px;
            margin-top: 11px;
            letter-spacing: 0.05em;
        }
        .text {
            font-size: 13px;
            line-height: 1.9;
            text-align: justify;
            margin-top: 15px;
            letter-spacing: 0.05em;
        }
    }
    @include _mq-down(md) {
        margin-top: 30px;
        .box {
            position: static;
            width: 100%;
            padding: 25px 20px 30px;
            .name {
                font-size: 18px;
            }
        }
    }
}

.principal-content-one {
    margin-top: 100px;
    @include _mq-down(md) {
        margin-top: 70px;
    }
}

.principal-content-show {
    position: relative;
    padding-top: 40px;
    margin-top: 30px;
    .box {
        position: absolute;
        left: 170px;
        top: 0;
        width: 300px;
        height: auto;
        padding: 20px 20px 20px;
        color: #fff;
        background-color: $color-brand;
        background-image: url(../img/common/bg_st_white30.png);
        background-size: 5px;
        .lead {
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0.05em;
            line-height: 1.5;
            text-align: center;
        }
        .text {
            font-size: 12px;
            line-height: 1.6667;
            text-align: justify;
            margin-top: 8px;
            letter-spacing: 0.05em;
        }
    }
    .tit {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 215px;
        height: 44px;
        color: $color-brand;
        font-size: 20px;
        font-weight: bold;
        line-height: 44px;
        text-align: center;
        background-color: #fff;
    }
    .image {
        display: flex;
    }
    @include _mq-down(md) {
        padding-top: 0px;
        .box {
            position: static;
            width: 100%;
            padding: 20px 20px 17px;
        }
        .tit {
            font-size: 16px;
            width: 180px;
            height: 34px;
            line-height: 34px;
        }

    }
}

.principal-content-seminar {
    position: relative;
    margin-top: 40px;
    padding-bottom: 40px;
    .box {
        position: absolute;
        right: 110px;
        bottom: 0;
        width: 366px;
        height: auto;
        padding: 20px 20px 20px;
        color: #fff;
        background-color: $color-brand;
        background-image: url(../img/common/bg_st_white30.png);
        background-size: 5px;
        .lead {
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0.05em;
            line-height: 1.667;
            text-align: center;
        }
        .text {
            font-size: 12px;
            line-height: 1.6667;
            text-align: justify;
            margin-top: 8px;
            letter-spacing: 0.05em;
        }
    }
    .tit {
        position: absolute;
        top: 0;
        right: 200px;
        width: 270px;
        height: 44px;
        color: $color-brand;
        font-size: 20px;
        font-weight: bold;
        line-height: 44px;
        text-align: center;
        background-color: #fff;
    }
    .image {
        display: flex;
    }
    @include _mq-down(md) {
        margin-top: 20px;
        padding-bottom: 0px;
        .box {
            position: static;
            width: 100%;
            padding: 20px 20px 17px;
        }
        .tit {
            top: auto;
            right: 0px;
            bottom: 0;
            font-size: 16px;
            width: 200px;
            height: 34px;
            line-height: 34px;
        }

    }
}

.principal-content-message {
    margin-top: 100px;
    .principal-video {
        max-width: 600px;
        margin: 50px auto 0;
    }
    .page-btn-center {
        margin-top: 50px;
    }
    @include _mq-down(md) {
        margin-top: 70px;
    }
}


