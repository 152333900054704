// Object - page

@include _mq-up(md) {
    .text-center-pc {
        text-align: center;
    }
    .u-d-flex {
        display: flex;
        flex-wrap: wrap;
        margin-left: -5%;
    }
    .flex-item.col2 {
        width: 50%;
        padding-left: 5%;
    }
}

.page-mainvisual {
    background: url(../img/common/bg_mainvisual.jpg) no-repeat center center/cover;
    padding: 142px 0 203px;
    @include _mq-down(md) {
        padding: 40px 0 45px;
        margin-top: 60px;
    }
}

.page-Content-block {
    margin-top: -140px;
    @include _mq-down(md) {
        margin-top: 0px;
    }
}

.page-content-area {
    padding: 130px 0;
    background: #fff;
    @include _mq-down(md) {
        //padding: 40px 0;
        padding: 0px 0;
    }
}

/**
* ページヘッドタイトル（ページ最上部タイトル）
**/
.page-head-tit {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    position: relative;
    line-height: 1.76;
    padding-bottom: 11px;
    letter-spacing: 0.15em;
    &::after {
        content: "";
        display: block;
        width: 30px;
        height: 3px;
        background: $color-brand;
        background: linear-gradient(90deg, $color-brand 0%, #8c0005 100%);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
    @include _mq-down(md) {
        font-size: 2.0rem;
        padding-bottom: 5px;
        &::after {
            width: 20px;
            height: 2px;
        }
    }
}

/**
* コンテンツタイトル（30×1pxの赤下線）
**/
.page-content-heading {
    /*margin-top: 100px;*//*共通にはできない*/
    .box {
        font-size: 15px;
        font-weight: 500;
        background: #fff;
        padding: 7px;
        text-align: center;
        width: 150px;
        margin: 0 auto;
        letter-spacing: 0.05em;
        &.red {
            padding: 9px;
            color: $color-brand;
            //color: #fff;
            border: 1px solid $color-brand;
            //border-radius: 3px;
            //background: $color-brand;
        }
    }
    .tit {
        font-size: 23px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.05em;
        position: relative;
        padding-bottom: 14px;
        margin-top: 10px;
        margin-bottom: 20px;
        line-height: 1.5;
        &::after {
            content: '';
            width: 30px;
            height: 3px;
            background: $color-brand;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
        }
        .min {
            font-size: 16px;
            font-weight: normal;
            padding-left: 3px;
        }
        &.brand {
            color: $color-brand;
        }
    }
    .tit-under {
        margin-top: 30px;
    }
    .lead {
        text-align: center;
        margin-top: 13px;
    }
    .u-d-flex p + ul + p {
        margin-top: 5px;
    }
    @include _mq-down(md) {
        margin-top: 70px;
        .tit {
            font-size: 20px;
            line-height: 1.5;
            padding-bottom: 12px;
            &::after {
                width: 20px;
                height: 2px;
            }
        }
        .lead {
            text-align: justify;
        }
    }
}

/**
* 35px装飾なしタイトル
**/
.page-text-tit-01 {
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.8;
    text-align: center;
    letter-spacing: 0.15em;
    padding-left: 0.15em;
    @include _mq-down(md) {
        font-size: 2.0rem;
        line-height: 1.7;
        letter-spacing: 0.075em;
        padding-left: 0.075em;
    }
    &.brand {
        color: $color-brand;
    }
}
.page-text-tit-01-under {
    margin-top: 20px;
}

/**
* 23px装飾なしタイトル
**/
.page-text-tit-02 {
    font-size: 2.3rem;
    font-weight: bold;
    line-height: 1.8;
    text-align: center;
    letter-spacing: 0.15em;
    padding-left: 0.15em;
    margin-bottom: 10px;
    @include _mq-down(md) {
        font-size: 1.6rem;
        line-height: 1.65;
        letter-spacing: 0.075em;
        padding-left: 0.075em;
    }
}
.page-text-tit-02-under {
    margin-top: 15px;
}

/**
* 18px装飾なしタイトル
**/
.page-text-tit-03 {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.8;
    text-align: center;
    letter-spacing: 0.15em;
    padding-left: 0.15em;
    margin-bottom: 10px;
    &.brand {
        color: $color-brand;
    }
    @include _mq-down(md) {
        font-size: 1.6rem;
        letter-spacing: 0.075em;
        padding-left: 0.075em;
        line-height: 1.65;
    }
}
.page-text-tit-03-under {
    margin-top: 10px;
}

/**
* 下線タイトル
**/
.page-unbar-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 15px;
    position: relative;
    margin-bottom: 13px;
    margin-top: 90px;
    letter-spacing: 0.05em;
    @include _mq-down(md) {
        margin-top: 50px;
        line-height: 1.4;
        padding-bottom: 11px;
    }
    &::before {
        content: '';
        width: 100%;
        height: 1px;
        background: #c8c8c8;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &::after {
        content: '';
        width: 200px;
        height: 1px;
        background: $color-brand;
        position: absolute;
        bottom: 0;
        left: 0;
        @include _mq-down(md) {
            width: 50px;
        }
    }
}

/**
* 左線タイトル
**/
.page-lbar-tit {
    font-size: 20px;
    font-weight: bold;
    padding: 5px 0 5px 19px;
    position: relative;
    letter-spacing: 0.05em;
    margin-top: 50px; /*デフォルト*/
    margin-bottom: 12px; /*デフォルト*/
    &::before {
        content: '';
        width: 5px;
        height: 30px;
        background: $color-brand;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto 0;

    }
}

/**
 * 文字白 ブランド背景 タイトル 左詰め
 **/
.brand-box-title {
    display: inline-block;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.1em;
    padding: 8px 25px;
    background-color: $color-brand;
    span {
        margin-left: -5px;
    }
    @include _mq-down(sm) {
        font-size: 12px;
        padding: 8px 15px;
    }
}

/**
* 下線タイトル AER用
**/
.red-unline-title {
    color: $color-brand;
    font-size: 2.3rem;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
    letter-spacing: 0.075em;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-brand;
    padding-bottom: 10px;
    @include _mq-down(md) {
        font-size: 1.8rem;
    }
}

/**
* スマホ時swipeアイコン
**/
.icon-swipe-sp {
    @include _mq-up(md) {
        display: none;
    }
    width: 246px;
    margin: 0 auto 13px;
}

/**
* 表形式
<div class="page-row-box">
    <div class="page-row-head">学校名</dt>
    <div class="page-row-list">専門学校金沢美専</dd>
</div>
**/
.page-row-box {
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    border-bottom: 1px solid #C8C8C8;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    &::after {
        content: "";
        width: 200px;
        height: 1px;
        background-color: $color-brand;
        position: absolute;
        left: 0;
        bottom: -1px;
    }
    .page-row-head {
        color: $color-brand;
        font-weight: bold;
        line-height: 1.6666;
        width: 220px;
    }
    .page-row-list {
        line-height: 1.7;
        a + a {
            margin-left: 15px;
        }
        li + li {
            margin-top: 5px;
        }
        li .spacer {
            margin-left: 1.4em;
        }
    }
}
@include _mq-down(sm) {
    .page-row-box {
        display: block;
        .page-row-head {
            width: 100%;
             margin-bottom: 5px;
        }
    }
}

/**
* link-text
**/
a.arrow-r-brand {
    position: relative;
    padding-left: 0.85em;
    transition: 0.1s;
    &::before {
        content: '';
        background: url("../img/common/arrow-r-brand.svg") left center / contain no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 5px;
        height: 9px;
        //transition: 0.1s;
    }
    @include _mq-up(md) {
        &:hover {
            color: $color-brand;
            &::before {
                //left: 2px;
            }
        }
    }
}

a.arrow-un-brand {
    position: relative;
    padding-left: 1.25em;
    &::before {
        content: '';
        background: url("../img/common/arrow-un-brand.svg") left center / contain no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 9px;
        height: 5px;
    }
}

a.target-r-bland {
    position: relative;
    padding-right: 1.1em;
    &::after {
        content: '';
        background: url("../img/common/icon_target_brand.svg") left center / contain no-repeat;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 12px;
        height: 9px;
    }
}

/**
* ぺージナビ
**/

.pnav {
    display: flex;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    &.head {
        margin-bottom: 100px;
        &.u-display-md {
            margin-bottom: 0;
        }
    }
    &.foot {
        margin-top: 115px;
        @include _mq-up(md) {
            padding-top: 100px;
            border-top: 1px solid #b3b3b3;
        }
    }
    &-item {
        width: 100%;
        border-right: 1px solid #b3b3b3;
        text-align: center;
        height: 40px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.1em;
        line-height: 1.3;
        span.txt-course {
            display:block;
            font-size: 85%;
            position: relative;
        }
        @include _mq-up(md) {
            display: flex;
            justify-content: center;
            align-items: center;
            a:hover {
                opacity: 0.6;
            }
        }
        &:nth-last-child(1) {
            border-right: 0;
        }
        a.current {
            color: $color-brand;
            pointer-events: none;
        }
    }
    @include _mq-down(md) {
        background: #e5e5e5;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        margin-bottom: 0;
        &-item {
            font-size: 13px;
            height: 45px;
            border-right: 1px solid #fff;
            letter-spacing: 0.05em;
            a {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
            }
            //ページ用
            a.current {
                color: #000;
                background: #fad0ce;
            }
        }
    }
    @include _mq-down(sm) {
        flex-wrap: wrap;
        &-item {
            width: 50%;
            &.odd {
                width: 100%;
                border-top: 1px solid #fff;
            }
            &:nth-child(even) {
                border-right: 0;
            }
        }
    }
}
.selectNav {
    .pnav-item {
        span {
            cursor: pointer;
        }
        &.select span {
            color: $color-brand;
            pointer-events: none;
        }
        @include _mq-down(md) {
            span {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
            }
            &.select span {
                color: #000;
                background: #fad0ce;
            }
        }
    }
}

.u-d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
