// Object - page - industry


.industry {
    display: flex;
    flex-wrap: wrap;
    & + .industry {
        margin-top: 50px;
        padding-top: 50px;
        border-top: 1px solid $color-brand;
    }
    &-img {
        width: 425px;
    }
    &-inner {
        width: 425px;
        margin-left: 50px;
        position: relative;
    }
    &-tit {
        color: $color-brand;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.6;
        letter-spacing: 0.1em;
        text-align: justify;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        &::before {
            content: '';
            margin-top: -0.25em;
            display: block;
        }
    }
    &-date {
        font-size: 14px;
        letter-spacing: 0.1em;
        margin-top: 15px;
    }
    &-txt {
        font-size: 14px;
        line-height: 2;
        letter-spacing: 0.1em;
        text-align: justify;
        padding-top: 15px;
        border-top: 1px dashed #ccc;
        margin-top: 20px;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        display: -webkit-box;
    }
    &-continue {
        font-size: 15px;
        letter-spacing: 0.1em;
        position: absolute;
        bottom: 0;
        a {
            color: $color-brand;
        }
        @include _mq-up(md) {
            &:hover {
                opacity: 0.6;
            }
        }
    }
    @include _mq-down(md) {
        &-img {
            width: 100%;
        }
        &-inner {
            width: 100%;
            margin-left: 0;
            margin-top: 20px;
        }
        &-tit {
            font-size: 15px;
            line-height: 1.6;
            letter-spacing: 0.1em;
        }
        &-date {
            font-size: 12px;
            margin-top: 12px;
        }
        &-txt {
            font-size: 13px;
            line-height: (25 / 13);
            letter-spacing: 0.05em;
            padding-top: 14px;
            margin-top: 20px;
        }
        &-continue {
            position: static;
            font-size: 13.5px;
            margin-top: 15px;
            letter-spacing: 0.05em;
        }
    }
}

.industry-post {
    &-tit {
        color: $color-brand;
        font-size: 23px;
        font-weight: bold;
        line-height: 1.8;
        letter-spacing: 0.1em;
        text-align: justify;
    }
    &-date {
        font-size: 14px;
        letter-spacing: 0.1em;
        margin-top: 15px;
    }
    &-content { //post
        margin-top: 50px;
    }
    @include _mq-down(md) {
        &-tit {
            font-size: 15px;
            line-height: 1.6;
            letter-spacing: 0.1em;
        }
        &-date {
            font-size: 12px;
            margin-top: 12px;
        }
    }
}

.s-post {
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.05em;
    img {
        margin: 30px 0;
    }
    h5 {
        margin-top: 70px;
        margin-bottom: 30px;
    }
    a {
        color: $color-brand;
        font-weight: 600;
        border-bottom: 1px solid rgba($color-brand, 0.4);
        @include _mq-up(md) {
            &:hover {
                border-bottom: 1px solid rgba($color-brand, 1);
            }
        }
    }
    .wp-block-video {
        width: 80%;
        margin: 30px auto;
    }
    @include _mq-down(md) {
        font-size: 13px;
        line-height: (25 / 13);
        img {
            margin: 20px 0;
        }
    }
}


