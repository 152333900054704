// Object - project - subject

/**
* 学科紹介
**/
body#subject main {
    background: #fff;
}

body#subject #footer {
    background: #fff;
}
.Subject {
    .page-content-area {
        background: rgb(251,226,236);
        transition: 2s;
    }
}

.Subject {
    .top-tit {
        font-size: 4rem;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.05em;
        position: relative;
        padding-bottom: 27px;
        &::after {
            content: '';
            width: 70px;
            height: 2px;
            background: $color-brand;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        span {
            font-size: 20px;
            letter-spacing: 0.05em;
            padding-left: 5px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            top: -5px;
        }
        @include _mq-down(md) {
            font-size: 25px;
            line-height: 1.4;
            text-align: center;
            padding-bottom: 15px;
            &::after {
                width: 20px;
                height: 2px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
            }
            span {
                display: block;
                font-size: 12px;
                padding-left: 0;
                top: 0;
            }
        }
    }
    .top-area {
        display: flex;
        position: relative;
        height: 413px;
        .photo {
            max-width: 630px;
            height: 413px;
            img {
                height: 100%;
                width: 100%;
            }
        }
        @include _mq-down(md) {
            display: block;
            height: auto;
            .photo {
                max-width: 100%;
                height: auto;
            }
        }
        .text-box {
            width: 630px;
            min-width: 530px;
            padding: 60px 52px 40px;
            background: #fdf3f7;
            & .inr {
                position: relative;
                width: 100%;
                height: 100%;
                @include _mq-up(md) {
                    &.selected {
                        display: flex;
                        flex-flow: column;
                        justify-content: center;
                        margin-top: -12px;
                    }
                }
            }
            @include _mq-down(md) {
                width: 100%;
                min-width: auto;
                padding: 25px 30px 30px 30px;
            }
        }
        .ann-box {
            display: flex;
            margin-top: 28px;
            .item {
                font-size: 15px;
                text-align: center;
                width: 140px;
                line-height: 1;
                padding: 9px 5px;
                border: 1px solid #909090;
                background: #fff;
                letter-spacing: 0.05em;
                & + .item {
                    margin-left: 10px;
                }
            }
            @include _mq-down(md) {
                justify-content: center;
                margin-top: 20px;
                .item {
                    font-size: 14px;
                    padding: 8px 10px;
                }
            }
        }
        .course-selected {
            font-size: 25px;
            font-weight: bold;
            color: $color-brand;
            letter-spacing: 0.05em;
            margin-top: 30px;
            @include _mq-down(md) {
                font-size: 22px;
                text-align: center;
                margin-top: 25px;
            }
        }
        .course-select {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            /*p {
                border-bottom: 1px solid #909090;
                padding-bottom: 10px;
                margin-bottom: 5px;
                padding-left: 5px;
                letter-spacing: 0.05em;
            }*/
            ul {
                li {
                    padding-left: 5px;
                    line-height: 1.8;
                    a {
                        font-size: 18px;
                        font-weight: bold;
                        padding-left: 15px;
                        letter-spacing: 0.05em;
                        background: url(../img/common/arrow-r-brand.svg) left center/ 7px 13px no-repeat;
                    }
                }
            }
            @include _mq-down(md) {
                position: static;
                margin-top: 25px;
                display: flex;
                justify-content: center;
                p {
                    margin-bottom: 8px;
                }
                ul {
                    display: block;
                    li {
                        a {
                            font-size: 14px;
                            padding-left: 12px;
                            background-size: 5px 9px;
                        }
                    }
                }
            }
        }
        .band-tips {
            color: #fff;
            font-size: 15px;
            line-height: 1;
            letter-spacing: 0.1em;
            padding: 10px 5px;
            width: 350px;
            text-align: center;
            background: $color-brand;
            margin-bottom: 25px;
            @include _mq-down(md) {
                font-size: 14px;
                width: 100%;
                max-width: 320px;
                margin: 0 auto 15px;
                padding: 8px 5px;
            }
        }
    }
    .page-content-area {
        @include _mq-down(md) {
            padding-top: 50px;
        }
    }
    .page-btn-column {
        margin-top: 50px;
    }
    .page-btn-center {
        margin-top: 30px;
    }
    .page-unbar-title {
        margin-top: 70px;
        @include _mq-down(md) {
            margin-top: 40px;
        }
    }
    .page-content-heading {
        @include _mq-down(md) {
            margin-top: 0;
            .u-d-flex-center {
                display: block;
                .sub {
                    display: block;
                    line-height: 1;
                    padding-bottom: 4px;
                }
            }
        }
    }
}

.subject-box-tit {
    font-size: 22px;
    font-weight: bold;
    border: 1px solid #606060;
    padding: 18px;
    width: 590px;
    margin: 95px auto 0;
    text-align: center;
    letter-spacing: 0.05em;
    background: #fff;
    position: relative;
    &::after {
        content: '';
        width: 1px;
        height: 14px;
        background: #606060;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -7px;
        margin: 0 auto;
    }
    @include _mq-down(md) {
        font-size: 16px;
        padding: 14px;
        margin: 55px auto 0;
        width: 100%;
        max-width: 335px;
    }
}

.subject-number-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;
    padding-bottom: 75px;
    border-bottom: 2px solid #fff;
    li {
        width: 280px;
        .upper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .number {
                font-size: 60px;
                color: $color-brand;
                width: 44px;
            }
            .tit {
                font-size: 20px;
                font-weight: bold;
                line-height: 1.4;
                letter-spacing: 0.05em;
                position: relative;
                top: 1px;
            }
        }
    }
    @include _mq-down(md) {
        margin-top: 30px;
        li {
            width: 100%;
            .upper {
                margin-bottom: 5px;
                .number {
                    font-size: 45px;
                    color: $color-brand;
                    width: 34px;
                }
                .tit {
                    font-size: 15px;
                }
            }
            & + li {
                margin-top: 30px;
            }
        }

    }
}

.subject-content-principal {
    padding-top: 55px;
    padding-bottom: 100px;
    border-bottom: 2px solid #fff;
    .subject-pri-head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;
        .image {
            width: 260px;
        }
        .text-area {
            width: 595px;
            .upper {
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 0.05em;
            }
            .name {
                font-size: 23px;
                font-weight: bold;
                letter-spacing: 0.05em;
                margin-top: 33px;
            }
            .text {
                font-size: 13px;
                line-height: (25 / 13);
                letter-spacing: 0.05em;
                margin-top: 25px;
                text-align: justify;
            }
            .text-link {
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 0.05em;
                margin-top: 25px;
                a {
                    color: $color-brand;
                    @include _mq-up(md) {
                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
        @include _mq-down(md) {
            display: block;
            .image {
                width: 280px;
                margin: 0 auto;
            }
            .text-area {
                width: 280px;
                margin: 15px auto 0;
                .upper {
                    font-size: 13px;
                    text-align: center;
                    line-height: (20 / 13);
                }
                .name {
                    font-size: 18px;
                    text-align: center;
                    margin-top: 18px;
                }
                .text {
                    margin-top: 22px;
                }
                .text-link {
                    margin-top: 18px;
                }
            }
        }
    }
    .subject-pri-foot {
        margin-top: 90px;
    }
}

.subject-content-top {
    padding-top: 110px;
    .image {
        width: 250px;
        margin: 40px auto 50px;
    }
    .graph {
        width: 100%;
    }
    @include _mq-down(md) {
        padding-top: 70px;
        .graph-cover {
            width: 100%;
            overflow-x: scroll;
        }
        .graph {
            width: 900px;
        }
    }
}

.subject-content-license {
    padding-top: 100px;
    @include _mq-down(md) {
        padding-top: 90px;
    }
}

.subject-content-admit {
    padding-top: 100px;
    .logo-admit {
        width: 95px;
        margin: 0 auto 23px;
    }
    .logo-icam {
        width: 146px;
        margin: 40px auto 23px;
    }
    @include _mq-down(md) {
        padding-top: 70px;
    }
}

.subject-content-curriculum {
    padding-top: 100px;
    .c-list-circle-white {
        .name {
            font-weight: 500;
        }
        .leader {
            letter-spacing: -0.4em;
            padding-right: 0.5em;
        }
        @include _mq-down(md) {
            .name {
                display: block;
            }
            .leader {
                display: none;
            }
        }
    }
    .grade {
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 5px;
        letter-spacing: 0.1em;
    }
    .line-t-txt {
        margin-top: 45px;
        border-top: 1px solid #c8c8c8;
        padding-top: 15px;
    }
    .text-tit {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 2;
    }
    @include _mq-down(md) {
        padding-top: 70px;
        .line-t-txt {
            margin-top: 25px;
        }
        .text-tit {
            font-size: 14px;
        }
    }
    .mt-spacer {
        margin-top: 10px;
    }
    .mt-spacer-02 {
        margin-top: 20px;
    }
}

.subject-content-pickup {
    padding-top: 100px;
    @include _mq-down(md) {
        padding-top: 70px;
    }
    .pickup-img {
        width: 280px;
        margin: 30px auto 0;
        &.unlayer {
            width: 350px;
        }
    }
    .pickup-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -30px;
        li {
            margin-top: 50px;
            padding-left: 30px;
            &.col3 {
                width: 33.3333%;
            }
            &.col2 {
                width: 45%;
            }
        }
        .name {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.05em;
            padding-bottom: 13px;
            margin-bottom: 9px;
            border-bottom: 1px solid #aaa;
        }
        @include _mq-up(md) {
            &.beauty, &.unlayer {
                justify-content: center;
                width: 610px;
                margin: 0 auto;
                li {
                    width: 280px;
                    padding-left: 0px;
                }
                li:nth-child(even) {
                    margin-left: 50px;
                }
            }
        }
        @include _mq-down(md) {
            li {
                margin-top: 30px;
                &.col3 {
                    width: 100%;
                }
                &.col2 {
                    width: 100%;
                }
            }
            .name {
                font-size: 16px;
            }
        }

    }
}

.subject-content-linkbtn {
    padding-top: 100px;
    @include _mq-down(md) {
        padding-top: 70px;
    }
}

/**
* 専攻
**/

.subject-content-reason {
    padding-top: 135px;
    .upper {
        background: $color-brand;
        padding: 40px 40px 15px;
        position: relative;
        .reason {
            position: absolute;
            top: -19px;
            left: 38px;
            min-width: 150px;
            margin: 0 auto;
            padding: 9px 20px;
            border: 2px solid #e6140a;
            color: #e6140a;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            letter-spacing: .05em;
            background: #fff;
        }
        .lead {
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.05em;
            line-height: 1.5;
        }
    }
    .box {
        border: 10px solid $color-brand;
        padding: 25px 30px;
        position: relative;
        background: #fff;
        .inr {
            max-width: 570px;
            font-size: 13px;
            line-height: 1.9231;
            letter-spacing: 0.05em;
            .name {
                margin-top: 10px;
                @include _mq-up(md) {
                    &.mt-spacer-01 {
                        margin-top: 30px;
                    }
                    &.mt-spacer-02 {
                        margin-top: 50px;
                    }
                }
            }
            .image {
                width: 220px;
                @include _mq-up(md) {
                    position: absolute;
                    bottom: 30px;
                    right: 30px;
                }
            }
        }
    }
    @include _mq-down(md) {
        padding-top: 95px;
        .upper {
            padding: 28px 21px 15px;
            .reason {
                max-width: 300px;
                font-size: 16px;
                top: -19px;
                left: 0;
                right: 0;
                margin: 0 auto;
                padding: 8px 10px;
            }
            .lead {
                font-size: 15px;
                text-align: center;
                line-height: 1.7333;
            }
        }
        .box {
            border: 5px solid $color-brand;
            padding: 25px 15px;
            .tit {
                font-size: 15px;
                text-align: left;
            }
            .inr {
                line-height: 1.6923;
                .name {
                    text-align: center;
                }
                .image {
                    margin: 18px auto 0;
                }
            }
        }
    }
}

/**
* ワーキングスタディ
**/

.subject-content-graph {
    .work-graph {
        display: block;
        margin-top: 30px;
        @include _mq-down(md) {
            width: 320px;
            margin: 30px auto 0;
        }
    }
}

.subject-content-graph {
    .work-graph-schedule-sp {
        width: 320px;
        margin: 30px auto 0;
        li {
            border: 1px solid $color-brand;
            font-size: 11px;
            text-align: center;
            height: 45px;
            display: flex;
            align-items: center;
            background: #fff;
            margin-top: 20px;
            padding-left: 35px;
            span {
                color: $color-brand;
                font-size: 15px;
                font-weight: bold;
                padding-right: 10px;
                padding-left: 10px;
                letter-spacing: 0.05em;
            }
            &.d-center {
                justify-content: center;
                padding-left: 0;
            }
            &.icon-Acc {
                position: relative;
                cursor: pointer;
                &::before {
                    content: '';
                    width: 1px;
                    height: 15px;
                    background: #000;
                    position: absolute;
                    right: 7px;
                    opacity: 1;
                    transition: .4s;
                    margin-right: 10px;
                }
                &::after {
                    content: '';
                    width: 15px;
                    height: 1px;
                    background: #000;
                    position: absolute;
                    right: 0;
                    margin-right: 10px;
                }
            }
        }
        li.Acc-trigger {
            &.Active {
                &.icon-Acc {
                    &::before {
                        transform: rotate(90deg);
                        opacity: 0;
                    }
                }
            }
        }
        .Acc-target {
            display: none;
            border: 1px solid $color-brand;
            border-top: 0;
            background: #fff;
        }
    }
}
