// Object - page - support

.scla-head-area {
    .upper {
        padding-left: .05em;
        font-size: 15px;
        text-align: center;
        letter-spacing: .05em;
        @include _mq-down(md) {
            font-size: 13px;
        }
    }
    .text-head {
        margin-top: 20px;
        display: block;
        width: 100%;
        @include _mq-down(md) {
            margin-top: 15px;
        }
    }
}

.support-content {
    margin-top: 110px;
    @include _mq-down(md) {
        margin-top: 60px;
    }
    .page-unbar-title:nth-of-type(1) {
        margin-top: 80px;
    }
    .page-unbar-title {
        margin-top: 60px;
    }
    .c-list-asterisk {
        font-size: 12px;
        line-height: 2;
        letter-spacing: .1em;
        margin-top: 3px;
    }
    .brand-box-title {
        margin: 18px 0 12px;
        &.second {
            margin-top: 35px
        }
        @include _mq-down(sm) {
            margin: 18px 0 8px;
            &.second {
                margin-top: 30px;
            }
        }
    }
    .colmun-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -50px;
    }
    .colmun {
        width: 50%;
        padding-left: 50px;
        @include _mq-down(sm) {
            width: 100%;
            & + .colmun {
                margin-top: 20px;
            }
            & + .colmun.second {
                margin-top: 0;
            }
        }
    }
    .content-tit {
        margin-top: 10px;
        line-height: 2;
        letter-spacing: .05em;
    }
    .mt-spacer {
        line-height: 2;
        margin-top: 20px;
        @include _mq-down(sm) {
            margin-top: _vw(20, 375);
        }
    }

    .fee {
        font-size: 18px;
        letter-spacing: 0.05em;
        text-align: center;
        margin-top: 45px;
        margin-bottom: 30px;
        dt {
            font-weight: bold;
        }
        dd {
            margin-top: 18px;
        }
        @include _mq-down(sm) {
            font-size: 14px;
            margin-top: 25px;
            margin-bottom: 15px;
            dt {
                font-size: 15px;
            }
            dd {
                margin-top: 10px;
                font-feature-settings: "palt" 1;
            }
        }
    }
    .pay {
        font-size: 15px;
        letter-spacing: 0.1em;
        padding-left: 0.1em;
        text-align: center;
        b {
            font-size: 18px;
        }
        @include _mq-down(sm) {
            font-size: 13px;
            b {
                font-size: 15px;
            }
        }
    }
}

.scholarship_table {
    width: 100%;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.05em;
    margin-top: 10px;
    margin-bottom: 15px;
    th, td {
        padding: 12px 25px;
        text-align: center;
        letter-spacing: 0.1em;
    }
    th {
        color: #fff;
        font-weight: normal;
        background: $color-brand;
        border: 1px solid $color-brand;
    }
    td {
        border: 1px solid $color-brand;
    }
    &.scholarship_table_01 {
        th {
            border-right: 1px solid #fff;
            &:nth-child(3) {
                border-right: 1px solid $color-brand;
            }
        }
    }
    &.scholarship_table_02 {
        th {
            border-bottom: 1px solid #fff;
        }
        tr:nth-last-child(1) {
            th {
                border-bottom: 1px solid $color-brand;
            }
        }
    }
    @include _mq-down(sm) {
        font-size: 13px;
        line-height: 1.7;
        letter-spacing: 0.05em;
        margin-top: 10px;
        margin-bottom: 15px;
        th, td {
            padding: 12px 10px;
            text-align: center;
        }
        &.scholarship_table_01 {
            th:nth-child(1) {
                width: 110px;
            }
        }
        &.scholarship_table_02 {
            th, td {
                padding: 10px 10px;
                text-align: center;
            }
        }
    }
}

.scholarship-case_table {
    width: 100%;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.05em;
    margin-top: 10px;
    margin-bottom: 15px;
    th, td {
        padding: 12px 25px;
        text-align: center;
        border: 1px solid $color-brand;
        letter-spacing: 0.1em;
    }
    thead {
        th {
            color: #fff;
            background: $color-brand;
        }
    }
    tbody {
        th {
            color: #fff;
            background: url("../../assets/img/common/bg_st_white30.png") left top / 5px 5px repeat;
            background-color: rgba($color-brand, 0.9);
        }
        td {
            text-align: right;
        }
    }
    .total {
        font-weight: bold;
    }
    @include _mq-down(sm) {
        font-size: _vw(13, 375);
        line-height: 1.5;
        letter-spacing: 0.05em;
        margin-top: _vw(5, 375);
        margin-bottom: _vw(10, 375);
        th, td {
            padding: _vw(8, 375) _vw(10, 375);
            text-align: center;
        }
    }
}

.c-unbar-brand {
    border-bottom: 1px solid $color-brand;
}

table.default-table {
    font-size: _vw(14);
    line-height: 2;
    letter-spacing: 0.05em;
    th {
        vertical-align: top;
    }
    td {
        //padding-left: 1em;
    }
    @include _mq-down(sm) {
        font-size: _vw(12, 375);
    }
}

.example-table {
    td.example {
        vertical-align: top;
    }
}

.support-download-content {
    padding-top: 60px;
    @include _mq-down(sm) {
        padding-top: 40px;
    }
    .lead {
        font-size: 16px;
        line-height: 2;
        letter-spacing: 0.15em;
        padding-left: 0.15em;
        font-weight: bold;
        text-align: center;
        @include _mq-down(sm) {
            font-size: 14px;
            line-height: 1.8;
            letter-spacing: 0.1em;
        }
    }
    .app-area {
        margin-top: 20px;
        .item {
            text-align: center;
            .image {
                width: 160px;
                margin: 7px auto 0;
                img {
                    width: 100%;
                }
            }
            .text {
                letter-spacing: 0.15em;
                img {
                    width: 100%;
                }
            }
            & + .item {
                margin-top: 20px;
            }
        }
        @include _mq-down(sm) {
            margin-top: 20px;
            .item {
                .image {
                    width: 160px;
                    img {
                        width: 100%;
                    }
                }
                .text {
                    width: 100%;
                    margin-left: 10px;
                }
                & + .item {
                    margin-top: 20px;
                }
            }
        }
    }
    .page-btn-column {
        margin-top: 25px;
        margin-bottom: 40px;
    }
    .page-btn-box {
        min-height: 55px;
    }
}




