// Object - project - license

/**
* 国家資格 国際ライセンス
**/
.License {
}

.license-content {
    .page-text-tit-03 {
        margin-top: 60px;
        @include _mq-down(md) {
            margin-top: 50px;
        }
    }
}

.license-content-support {
    margin-top: 100px;
    .sub {
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 13px;
    }
    .page-unbar-title {
        margin-top: 0;
    }
    @include _mq-down(md) {
        margin-top: 25px;
        .sub {
            font-size: 15px;
            margin-top: 15px;
            margin-bottom: 10px;
            line-height: 1.6667;
        }
        .flex-item + .flex-item {
            margin-top: 25px;
        }
    }

}

.license-content-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -10px;
    margin-top: 30px;
    & + .license-content-list {
        margin-top: 40px;
    }
    .item {
        position: relative;
        @include _mq-up(md) {
            & + .item {
                margin-left: 20px;
            }
        }
    }
    .circle {
        color: #fff;
        font-size: 25px;
        width: 40px;
        height: 40px;
        background: $color-brand;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: content-box;
        @include _mq-up(md) {
            border: 10px solid #fff;
            position: absolute;
            top: -10px;
            left: 0;
        }
    }
    .box {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        line-height: 1.3889;
        margin-left: 30px;
        letter-spacing: 0.05em;
        @include _mq-up(md) {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $color-brand;
            height: 100px;
            &.col3 {
                width: 260px;
            }
            &.col2 {
                width: 400px;
            }
        }
    }
    @include _mq-down(md) {
        display: block;
        margin-top: 25px;
        & + .license-content-list {
            margin-top: 0px;
        }
        .item {
            display: flex;
            align-items: center;
            border-bottom: 1px dotted $color-brand;
            padding-bottom: 18px;
            margin-bottom: 16px;
        }
        .circle {

        }
        .box {
            font-size: 15px;
            line-height: 1.3889;
            text-align: justify;
            margin-left: 10px;
        }
    }

}

.license-content-step {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-brand;
    padding-bottom: 50px;
    li {
        width: 280px;
    }
    .upper {
        display: flex;
        align-items: center;
        border-bottom: 1px dotted $color-brand;
        padding-bottom: 11px;
        margin-bottom: 10px;
        min-height: 87px;
        .inr {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .step {
            color: $color-brand;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 0.02em;
            text-align: center;
        }
        .circle {
            color: #fff;
            font-size: 25px;
            width: 40px;
            height: 40px;
            background: $color-brand;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3px;
        }
        .tit {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.3889;
            margin-left: 11px;
            letter-spacing: 0.05em;
        }
    }
    @include _mq-down(md) {
        display: block;
        margin-top: 65px;
        li {
            width: 100%;
            & + li {
                margin-top: 30px;
            }
        }
        .upper {
            padding-bottom: 9px;
            margin-bottom: 6px;
            min-height: auto;
            .step {
                font-size: 12px;
            }
            .circle {
                font-size: 22px;
                width: 35px;
                height: 35px;
                margin-top: 2px;
            }
            .tit {
                font-size: 15px;
                margin-top: 12px;

            }

        }
    }
}

.license-content-news {
    margin-top: 135px;
    .upper {
        background: $color-brand;
        padding: 40px 40px 25px;
        position: relative;
        .news {
            position: absolute;
            top: -19px;
            left: 38px;
            min-width: 150px;
            margin: 0 auto;
            padding: 7px;
            border: 2px solid #e6140a;
            color: #e6140a;
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            letter-spacing: .05em;
            background: #fff;
        }
        .tit {
            color: #fff;
            font-size: 25px;
            font-weight: bold;
            letter-spacing: 0.05em;
        }
        .lead {
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.05em;
            margin-top: 15px;
            margin-bottom: 13px;
        }
        .text {
            color: #fff;
            font-size: 14px;
            line-height: 1.7143;
            letter-spacing: 0.05em;
            text-align: justify;
        }
    }
    .box {
        border: 10px solid $color-brand;
        padding: 30px;
        .tit {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            letter-spacing: 0.05em;
        }
        .inr {
            font-size: 13px;
            line-height: 1.9231;
            margin-top: 15px;
            letter-spacing: 0.05em;
            p {
                margin-top: 7px;
            }

        }
    }
    @include _mq-down(md) {
        margin-top: 95px;
        .upper {
            padding: 30px 21px 15px;
            .news {
                font-size: 17px;
                top: -19px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
            .tit {
                font-size: 18px;
                text-align: center;
            }
            .lead {
                font-size: 15px;
                text-align: center;
                line-height: 1.7333;
                margin-top: 10px;
                margin-bottom: 8px;
            }
            .text {
                font-size: 13px;
            }
        }
        .box {
            border: 5px solid $color-brand;
            padding: 25px 15px;
            .tit {
                font-size: 15px;
                text-align: left;
            }
            .inr {
                line-height: 1.6923;
                p {
                    margin-top: 12px;
                }
            }
        }
    }
}


