@charset "UTF-8";
/*normal*/
/**
* 就職実績
**/
@media screen and (max-width: 767px) {
  .Achievement .page-text-tit-01 {
    text-align: justify; } }

.achievement-content-data .mt-spacer {
  margin-top: 90px; }

.achievement-content-data .page-content-heading {
  margin-top: 100px; }
  @media screen and (max-width: 767px) {
    .achievement-content-data .page-content-heading {
      margin-top: 70px; } }

.achievement-content-support .page-content-heading {
  margin-top: 100px; }
  @media screen and (max-width: 767px) {
    .achievement-content-support .page-content-heading {
      margin-top: 70px; } }

.achievement-date-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px; }
  .achievement-date-list li {
    width: 290px;
    background: #E6E6E6;
    padding: 20px 0 18px; }
  .achievement-date-list .tit {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.05em;
    text-align: center; }
  .achievement-date-list .percent {
    color: #E6140A;
    font-size: 55px;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    letter-spacing: 0.05em; }
    .achievement-date-list .percent span {
      font-size: 30px; }
  @media screen and (max-width: 767px) {
    .achievement-date-list {
      display: block; }
      .achievement-date-list li {
        width: 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        margin-left: auto;
        padding: 9px 0 11px; }
        .achievement-date-list li + li {
          margin-top: 10px; }
      .achievement-date-list .tit {
        font-size: 16px;
        margin-top: 2px; }
      .achievement-date-list .percent {
        font-size: 30px;
        margin-top: 0px;
        margin-left: 10px; }
        .achievement-date-list .percent span {
          font-size: 16px; } }

.achievement-check-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  margin: 55px auto 0; }
  @media screen and (max-width: 767px) {
    .achievement-check-list {
      max-width: 100%;
      display: block; } }
  .achievement-check-list li {
    width: 325px;
    position: relative;
    border: 1px solid #000;
    padding: 30px 25px 25px; }
    @media screen and (min-width: 768px) {
      .achievement-check-list li:nth-of-type(even) {
        margin-left: 50px; }
      .achievement-check-list li:nth-of-type(n+3) {
        margin-top: 62px; } }
    @media screen and (max-width: 767px) {
      .achievement-check-list li {
        width: 335px;
        margin: 55px auto 0;
        padding: 30px 20px 25px; } }
  .achievement-check-list .check {
    color: #E6140A;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    width: 150px;
    padding: 7px;
    border: 2px solid #E6140A;
    background: #fff;
    letter-spacing: 0.05em;
    position: absolute;
    top: -22px;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .achievement-check-list .tit {
    color: #E6140A;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.05em;
    line-height: 1.625;
    min-height: 52px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .achievement-check-list .text {
    font-size: 13px;
    line-height: 1.9231;
    letter-spacing: 0.05em;
    text-align: justify; }

.achievement-content-interview .center {
  text-align: center; }

.achievement-content-interview .page-content-heading {
  margin-top: 120px; }
  @media screen and (max-width: 767px) {
    .achievement-content-interview .page-content-heading {
      margin-top: 70px; } }

.achievement-voice-card {
  display: flex;
  justify-content: space-between; }
  @media screen and (min-width: 768px) {
    .achievement-voice-card:nth-of-type(1) {
      margin-top: 50px; }
    .achievement-voice-card:nth-of-type(even) {
      margin-top: 50px;
      flex-direction: row-reverse; }
    .achievement-voice-card + .achievement-voice-card {
      margin-top: 105px; } }
  .achievement-voice-card .image-area {
    width: 425px; }
  .achievement-voice-card .text-area {
    width: 425px; }
    .achievement-voice-card .text-area .tit {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.6;
      letter-spacing: 0.05em;
      text-align: justify;
      margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .achievement-voice-card {
      display: block; }
      .achievement-voice-card:nth-of-type(1) {
        margin-top: 30px; }
      .achievement-voice-card + .achievement-voice-card {
        margin-top: 40px; }
      .achievement-voice-card .image-area {
        width: 100%;
        margin-bottom: 14px; }
      .achievement-voice-card .text-area {
        width: 100%; }
        .achievement-voice-card .text-area .tit {
          font-size: 15px; } }

.achievement-content-job .mt-spacer {
  margin-top: 25px; }

.achievement-content-job p {
  text-align: left;
  letter-spacing: 0.1em; }
  .achievement-content-job p + p {
    margin-top: 1.5em; }

.achievement-content-job .page-content-heading {
  margin-top: 120px; }
  @media screen and (max-width: 767px) {
    .achievement-content-job .page-content-heading {
      margin-top: 70px; } }

.achievement-content-company .mt-spacer {
  margin-top: 25px; }

.achievement-content-company strong {
  display: block;
  margin-top: 1.5em; }

.achievement-content-company .page-content-heading {
  margin-top: 120px; }
  @media screen and (max-width: 767px) {
    .achievement-content-company .page-content-heading {
      margin-top: 70px; } }

.Admission .page-content-heading {
  margin-top: 0; }

.admission-flow-content {
  margin-top: 40px; }
  .admission-flow-content .box {
    max-width: 800px;
    margin: 0 auto 0;
    padding: 25px 0px 0;
    position: relative; }
    .admission-flow-content .box + .box {
      margin-top: 25px; }
    @media screen and (max-width: 767px) {
      .admission-flow-content .box {
        padding: 25px 0px 0; } }
    .admission-flow-content .box .inr {
      border: 1px solid #E6140A;
      line-height: 2;
      padding: 2.8em 6em 2em;
      font-size: 1.4rem;
      letter-spacing: 0.05em; }
      @media screen and (max-width: 767px) {
        .admission-flow-content .box .inr {
          padding: 2.8em 2em 2em; } }
    .admission-flow-content .box .head {
      display: block;
      font-weight: bold;
      text-align: center;
      color: #fff;
      background-color: #E6140A;
      padding: .5em 0;
      margin: auto;
      max-width: 300px;
      line-height: 2;
      letter-spacing: 0.1em;
      position: absolute;
      top: 0;
      left: 0;
      right: 0; }
    .admission-flow-content .box .arrow {
      display: block;
      margin: 25px auto 0;
      width: 35px; }
    .admission-flow-content .box.wrap .arrow {
      padding-top: 17px; }

.application-nav-list {
  display: flex;
  justify-content: center;
  margin-top: 40px; }
  .application-nav-list li {
    width: 25%;
    text-align: center;
    border-right: 1px solid #c8c8c8; }
    .application-nav-list li:nth-child(1) {
      border-left: 1px solid #c8c8c8; }
    .application-nav-list li a {
      display: block;
      padding: 1.0em 0;
      font-size: 1.4rem;
      line-height: 1;
      letter-spacing: 0.1em; }
      @media screen and (min-width: 768px) {
        .application-nav-list li a:hover {
          color: #E6140A; } }
  @media screen and (max-width: 767px) {
    .application-nav-list {
      width: auto; }
      .application-nav-list li {
        width: 25%; }
        .application-nav-list li a {
          padding: 0.5em 10px;
          line-height: 1.3; } }
  @media screen and (max-width: 499px) {
    .application-nav-list li {
      width: auto; }
      .application-nav-list li a {
        font-size: 1.3rem; } }

/*.admission-policy-content {
  .box {
    background: url(../img/common/bg_stripe_brand.png);
    width: 80%;
    margin: 0 auto;
    padding: 10px;
    margin: 0 auto 8em;
  }
  .inner {
    background-color: #fff;
    padding: 3em 4em 3.6em;
  }
  .page-content-heading + .page-content-heading {
    margin-top: 50px;
  }
  p {
    text-align: center;
  }
  @include _mq-down(md) {
    .page-content-heading {
      margin-top: 0;
    }
  }
  @include _mq-down(sm) {
    .box {
      width: 100%;
    }
  }
}*/
.entrance-inner {
  margin-top: 30px; }

.flow-inner {
  margin-top: 15px; }

.entrance-table {
  width: 100%; }
  .entrance-table tr {
    border-bottom: 1px solid #E6140A; }
  @media screen and (min-width: 768px) {
    .entrance-table.ao-appli th:nth-child(1) {
      width: 72%; }
    .entrance-table th, .entrance-table td {
      width: 50%; }
    .entrance-table th + th {
      border-left: 1px solid #fff; }
    .entrance-table td:nth-last-child(1) {
      border-left: 1px solid #E6140A;
      text-align: center; } }
  .entrance-table th {
    color: #fff;
    font-weight: 500;
    font-size: 1.4rem;
    background-color: #E6140A;
    text-align: center;
    padding: 1.4em 1.6em;
    letter-spacing: 0.075em; }
  .entrance-table td {
    font-size: 1.3rem;
    line-height: 1.7;
    position: relative;
    padding: 1.4em 1.6em;
    letter-spacing: 0.05em; }
    @media screen and (min-width: 768px) {
      .entrance-table td .date {
        margin-top: -40px; } }
    .entrance-table td .mt-spacer {
      margin-top: 1em; }
    .entrance-table td .bg-gray {
      padding: .4em 0;
      text-align: center;
      background-color: #DCDCDC;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 20px;
      width: 90%; }
    .entrance-table td .note {
      font-size: 90%;
      margin-top: 7px; }
  @media screen and (max-width: 767px) {
    .entrance-table {
      display: block; }
      .entrance-table tr {
        width: 100%; }
      .entrance-table th {
        padding: 1.0em 1.6em;
        display: block; }
      .entrance-table td {
        padding: 1.4em 0.5em;
        display: block; }
        .entrance-table td .bg-gray {
          position: static;
          margin-top: 15px;
          width: 100%; }
      .entrance-table td:nth-last-child(1) {
        text-align: center; } }

.entrance-date-table {
  width: 48%;
  margin: 30px auto 20px; }
  .entrance-date-table dt, .entrance-date-table dd {
    border: 1px solid #E6140A;
    text-align: center; }
  .entrance-date-table dt {
    color: #fff;
    font-weight: 500;
    font-size: 1.4rem;
    background-color: #E6140A;
    padding: 1em;
    letter-spacing: 0.075em; }
  .entrance-date-table dd {
    color: #E6140A;
    font-size: 1.4rem;
    line-height: 1.7;
    position: relative;
    vertical-align: middle;
    padding: 1em;
    letter-spacing: 0.05em; }
    .entrance-date-table dd + dd {
      border-top: 0; }
    .entrance-date-table dd span:nth-child(1) {
      display: inline-block;
      width: 70px; }
    .entrance-date-table dd span:nth-child(2) {
      display: inline-block;
      width: 150px;
      text-align: left; }
  @media screen and (max-width: 499px) {
    .entrance-date-table {
      width: 80%; } }

.admission-howtoapply-content .send-address {
  display: inline-block;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.5em; }

.admission-howtoapply-content .tit {
  font-weight: bold;
  margin-top: 1em; }

.admission-howtoapply-content .howtoapply-table td:nth-child(1) {
  width: 6.25em;
  vertical-align: top; }

.admission-howtoapply-content .mt-spacer {
  margin-top: 1em; }

.admission-howtoapply-content .page-btn-column {
  margin-top: 40px; }

.admission-web-content .tit {
  font-size: 15px;
  font-weight: 600;
  margin-top: 0.5em; }

.admission-web-content .c-list-dot.column {
  display: flex; }
  @media screen and (min-width: 500px) {
    .admission-web-content .c-list-dot.column li {
      padding-right: 10px; } }
  @media screen and (max-width: 499px) {
    .admission-web-content .c-list-dot.column {
      display: block; } }

.admission-web-content .mt-spacer {
  margin-top: 2em; }

.entrance-support-table th, .entrance-support-table td {
  font-size: 1.2rem;
  line-height: 1.7;
  letter-spacing: 0.03em; }

.entrance-support-table tr {
  border-bottom: 1px dotted #808080; }

.entrance-support-table th {
  text-align: justify;
  padding: 8px 10px 8px 5px;
  color: #E6140A;
  font-weight: normal;
  text-align: justify;
  width: 140px; }

.entrance-support-table td {
  width: auto;
  padding: 8px 5px 8px 10px; }

@media screen and (max-width: 499px) {
  .entrance-support-table tr {
    display: block;
    padding: 10px 0 10px 0; }
  .entrance-support-table th, .entrance-support-table td {
    display: block;
    padding: 0;
    width: 100%; } }

.entrance-under-arrow {
  display: block;
  margin: 35px auto 35px;
  width: 35px; }

.cell-Justify {
  display: flex;
  justify-content: space-between; }

/**
* 学納金について
**/
.admission-expense-content .page-unbar-title .year {
  color: #888;
  font-size: 83.333%;
  border-left: 1px solid #888;
  padding-left: 10px;
  margin-left: 10px;
  font-weight: normal; }

.admission-expense-content .c-list-square {
  margin-top: 20px;
  line-height: 1.8; }
  .admission-expense-content .c-list-square span {
    font-weight: bold; }
  .admission-expense-content .c-list-square li::before {
    color: #E6140A; }

.admission-expense-content .other-list {
  font-size: 13px;
  line-height: 1.8;
  margin-top: 15px;
  letter-spacing: 0.05em; }
  .admission-expense-content .other-list .tit {
    font-weight: bold; }

.admission-expense-content .icon-swipe-sp {
  margin: 15px auto 0; }

.expense-table {
  width: 100%;
  margin-top: 10px; }
  .expense-table th, .expense-table td {
    border: 1px solid #E6140A;
    font-weight: normal; }
  .expense-table th {
    vertical-align: middle;
    padding: 1em 1em;
    text-align: center;
    letter-spacing: 0.1em; }
  .expense-table td {
    text-align: right;
    padding: 2em 1em;
    letter-spacing: 0.05em; }
  .expense-table thead tr:nth-child(1) th:nth-last-child(1) {
    border-right: 1px solid #E6140A; }
  .expense-table thead th {
    color: #fff;
    background-color: #E6140A;
    border: 1px solid #fff; }
    .expense-table thead th:nth-child(1) {
      border-left: 1px solid #E6140A; }
  .expense-table tbody th {
    background-color: #eee;
    border: 1px solid #E6140A; }

@media screen and (max-width: 767px) {
  .expense-table-cover {
    overflow-x: scroll; }
  .expense-table {
    width: 800px; } }

.expense-flow-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 30px; }
  .expense-flow-content .item {
    width: 21%;
    border: 1px solid #E6140A; }
  .expense-flow-content .head {
    color: #fff;
    font-weight: bold;
    font-size: 1.4rem;
    text-align: center;
    background-color: #E6140A;
    padding: 10px 0;
    letter-spacing: 0.1em; }
  .expense-flow-content .inr {
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    text-align: center;
    line-height: 1.5;
    letter-spacing: 0.075em; }
  .expense-flow-content img {
    display: block;
    height: 21px;
    width: auto; }
  @media screen and (max-width: 767px) {
    .expense-flow-content {
      display: block; }
      .expense-flow-content .item {
        width: 100%; }
      .expense-flow-content img {
        transform: rotate(90deg);
        text-align: center;
        margin: 10px auto;
        height: 20px; } }

.admission-stop-box {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.6em 6em;
  border: 1px solid #E6140A;
  margin-top: 30px; }
  .admission-stop-box .c-text {
    text-align: center; }
  .admission-stop-box .b-brand {
    color: #E6140A;
    font-weight: bold; }
  .admission-stop-box .phone {
    padding-left: 1em; }
  @media screen and (max-width: 499px) {
    .admission-stop-box {
      padding: 1.6em 20px;
      margin-top: 20px; }
      .admission-stop-box .phone {
        padding-left: 0;
        font-size: 16px; } }

.admission-support-box {
  text-align: center;
  background: url(../img/common/bg_stripe_gray.jpg);
  padding: 2em 1em;
  margin-top: 50px; }
  .admission-support-box p {
    font-size: 1.2rem;
    margin-bottom: 1.8em;
    line-height: 1.7;
    letter-spacing: 0.05em; }
  .admission-support-box .page-btn-center {
    margin-top: 15px; }

.admission-comment {
  margin-top: 50px; }
  .admission-comment .c-list-square .mt-spacer {
    margin-top: 20px; }

.admission-support-content .catch {
  color: #E6140A;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.05em;
  margin-top: 30px; }

.admission-support-content .grade {
  color: #888;
  font-size: 83.333%;
  border-left: 1px solid #888;
  padding-left: 10px;
  margin-left: 10px;
  font-weight: normal; }

.admission-schola-table {
  margin-top: 30px;
  width: 100%; }
  .admission-schola-table > tbody > tr {
    border-bottom: 1px solid #E6140A; }
    .admission-schola-table > tbody > tr > th, .admission-schola-table > tbody > tr > td {
      padding: 15px 20px;
      line-height: 1.7;
      font-weight: normal; }
    .admission-schola-table > tbody > tr > th {
      width: 200px;
      text-align: left;
      color: #fff;
      background-color: #E6140A;
      border-bottom: 1px solid #fff;
      letter-spacing: 0.1em;
      vertical-align: top; }
    .admission-schola-table > tbody > tr > td {
      letter-spacing: 0.05em; }
  .admission-schola-table > tbody > tr:first-child {
    border-top: 1px solid #E6140A; }
  .admission-schola-table > tbody > tr:last-child > th {
    border-bottom: 0; }
  .admission-schola-table dl.exam {
    display: flex; }
    .admission-schola-table dl.exam dt {
      width: 30px; }
  .admission-schola-table .train-table {
    width: auto;
    /*span {
            letter-spacing: -0.4em;
            padding-right: 0.4em;
        }*/ }
    .admission-schola-table .train-table td {
      padding-right: 1em; }
  @media screen and (max-width: 767px) {
    .admission-schola-table > tbody > tr > th, .admission-schola-table > tbody > tr > td {
      display: block;
      width: 100%;
      padding: 10px 15px; }
    .admission-schola-table .train-table {
      width: 100%; }
      .admission-schola-table .train-table td {
        display: block; }
      .admission-schola-table .train-table td + td {
        padding-left: 4.68em; } }

.skillup-table th {
  font-weight: normal;
  width: 70px; }

.skillup-table .kakaku {
  width: 60px; }

.skillup-table .menjo {
  position: relative;
  padding: 0 10px; }

.skillup-table .menjo::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  background: url("../../assets/img/admission/right_bracket.svg") no-repeat;
  background-size: contain;
  width: 7px;
  height: 65px; }

.skillup-table .menjo::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  background: url("../../assets/img/admission/left_bracket.svg") no-repeat;
  background-size: contain;
  width: 7px;
  height: 65px; }

/**
* 書類
**/
.admission-doc-youkou {
  margin-bottom: 120px; }
  .admission-doc-youkou figure {
    width: 225px;
    margin: 30px auto 0;
    border: 1px solid #ccc; }
  .admission-doc-youkou .page-btn-center {
    margin-top: 30px; }

/**
* ポリシー
**/
.Policy .page-text-tit-02 {
  margin-top: 20px; }
  @media screen and (max-width: 767px) {
    .Policy .page-text-tit-02 {
      margin-top: 15px; } }

.Policy .page-text-tit-02-under {
  text-align: center;
  margin-top: 5px; }
  @media screen and (max-width: 767px) {
    .Policy .page-text-tit-02-under {
      text-align: justify;
      margin-top: 0px; } }

.Policy-img {
  margin-top: 25px; }

.Policy .page-content-heading {
  margin-top: 90px; }
  .Policy .page-content-heading .tit {
    padding-left: 0.5em; }
  @media screen and (max-width: 767px) {
    .Policy .page-content-heading {
      margin-top: 70px; } }

.policy {
  margin-top: 30px; }
  .policy + .policy {
    margin-top: 30px; }
  .policy .left {
    float: left; }
  .policy .right {
    float: right; }
  .policy-box {
    width: 570px;
    margin-top: 30px; }
  .policy-tit {
    font-size: 23px;
    font-weight: bold;
    color: #E6140A;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em; }
    .policy-tit span {
      font-size: 34px;
      letter-spacing: 0.05em; }
  .policy-txt {
    margin-top: 10px; }
  .policy-img {
    width: 280px;
    margin-left: 50px;
    display: block; }
  @media screen and (max-width: 767px) {
    .policy + .policy {
      margin-top: 50px; }
    .policy .left, .policy .right {
      float: none; }
    .policy-box {
      width: 100%; }
    .policy-img {
      width: 280px;
      margin: 20px auto 0; }
    .policy-txt {
      margin-top: 12px; }
    .policy-tit {
      font-size: 18px; }
      .policy-tit span {
        font-size: 26px; } }

/**
* 充実した実践教育
**/
.education-content-01 {
  padding-bottom: 100px;
  border-bottom: 1px solid #E6140A; }
  .education-content-01 .image01 {
    margin: 45px 0 20px; }
  .education-content-01 .wrapper {
    max-width: 800px;
    margin: 70px auto 0; }
    .education-content-01 .wrapper .upper img {
      display: block;
      height: 39px;
      margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .education-content-01 {
      padding-bottom: 40px; }
      .education-content-01 .image01 {
        margin: 25px 0 0px; }
      .education-content-01 .wrapper {
        margin: 50px auto 0; }
        .education-content-01 .wrapper .upper img {
          height: 32px;
          margin-bottom: 10px; }
      .education-content-01 .flex-item + .flex-item {
        margin-top: 50px; }
      .education-content-01 .page-text-tit-02.sp-l {
        text-align: justify; }
      .education-content-01 .page-text-tit-03 {
        font-size: 15px;
        text-align: justify; } }

.education-b-content-02 {
  padding-top: 130px; }
  @media screen and (max-width: 767px) {
    .education-b-content-02 {
      padding-top: 70px; } }

.education-h-content-02 {
  padding-top: 90px;
  padding-bottom: 100px;
  border-bottom: 1px solid #E6140A; }
  .education-h-content-02 .image02 {
    margin: 32px 0 0; }
  @media screen and (max-width: 767px) {
    .education-h-content-02 {
      padding-top: 32px;
      padding-bottom: 40px; }
      .education-h-content-02 .page-text-tit-03 {
        font-size: 15px;
        text-align: justify; }
      .education-h-content-02 .image02 {
        margin: 25px 0 0px; } }

.education-point-list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .education-point-list li {
    max-width: 279px;
    padding: 0 15px;
    box-sizing: content-box; }
    .education-point-list li .upper {
      display: flex;
      align-items: center; }
      .education-point-list li .upper .point {
        display: inline-block;
        color: #E6140A;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.3;
        text-align: center;
        letter-spacing: 0.05em;
        position: relative;
        padding-right: 24px; }
        .education-point-list li .upper .point::after {
          content: '';
          width: 20px;
          height: 53px;
          background: url("../img/education/education_arrow-r-brand.svg") right center/20px 53px no-repeat;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto 0; }
      .education-point-list li .upper .tit {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.6667;
        letter-spacing: 0.05em;
        margin-left: 11px; }
    .education-point-list li .image {
      margin: 20px 0 13px; }
  @media screen and (min-width: 768px) {
    .education-point-list li + li {
      border-left: 1px dashed #E6140A; } }
  @media screen and (max-width: 767px) {
    .education-point-list {
      display: block; }
      .education-point-list li {
        max-width: 100%; }
        .education-point-list li .upper .point {
          font-size: 14px; }
          .education-point-list li .upper .point::after {
            width: 17px;
            height: 45px; }
        .education-point-list li .upper .tit {
          font-size: 16px;
          line-height: 1.5; }
        .education-point-list li .image {
          margin: 15px 0 10px; }
      .education-point-list li + li {
        border-top: 1px dashed #E6140A;
        padding-top: 30px;
        margin-top: 30px; } }

.js_Hover {
  transition: 0.7s; }

.js_Hover:hover, .js_Hover:focus {
  transform: scale(1.05); }

.industry {
  display: flex;
  flex-wrap: wrap; }
  .industry + .industry {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid #E6140A; }
  .industry-img {
    width: 425px; }
  .industry-inner {
    width: 425px;
    margin-left: 50px;
    position: relative; }
  .industry-tit {
    color: #E6140A;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: 0.1em;
    text-align: justify;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box; }
    .industry-tit::before {
      content: '';
      margin-top: -0.25em;
      display: block; }
  .industry-date {
    font-size: 14px;
    letter-spacing: 0.1em;
    margin-top: 15px; }
  .industry-txt {
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.1em;
    text-align: justify;
    padding-top: 15px;
    border-top: 1px dashed #ccc;
    margin-top: 20px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box; }
  .industry-continue {
    font-size: 15px;
    letter-spacing: 0.1em;
    position: absolute;
    bottom: 0; }
    .industry-continue a {
      color: #E6140A; }
    @media screen and (min-width: 768px) {
      .industry-continue:hover {
        opacity: 0.6; } }
  @media screen and (max-width: 767px) {
    .industry-img {
      width: 100%; }
    .industry-inner {
      width: 100%;
      margin-left: 0;
      margin-top: 20px; }
    .industry-tit {
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: 0.1em; }
    .industry-date {
      font-size: 12px;
      margin-top: 12px; }
    .industry-txt {
      font-size: 13px;
      line-height: 1.92308;
      letter-spacing: 0.05em;
      padding-top: 14px;
      margin-top: 20px; }
    .industry-continue {
      position: static;
      font-size: 13.5px;
      margin-top: 15px;
      letter-spacing: 0.05em; } }

.industry-post-tit {
  color: #E6140A;
  font-size: 23px;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.1em;
  text-align: justify; }

.industry-post-date {
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 15px; }

.industry-post-content {
  margin-top: 50px; }

@media screen and (max-width: 767px) {
  .industry-post-tit {
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.1em; }
  .industry-post-date {
    font-size: 12px;
    margin-top: 12px; } }

.s-post {
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.05em; }
  .s-post img {
    margin: 30px 0; }
  .s-post h5 {
    margin-top: 70px;
    margin-bottom: 30px; }
  .s-post a {
    color: #E6140A;
    font-weight: 600;
    border-bottom: 1px solid rgba(230, 20, 10, 0.4); }
    @media screen and (min-width: 768px) {
      .s-post a:hover {
        border-bottom: 1px solid #e6140a; } }
  .s-post .wp-block-video {
    width: 80%;
    margin: 30px auto; }
  @media screen and (max-width: 767px) {
    .s-post {
      font-size: 13px;
      line-height: 1.92308; }
      .s-post img {
        margin: 20px 0; } }

/**
* 学校情報
**/
.info-content + .info-content {
  margin-top: 120px; }
  @media screen and (max-width: 767px) {
    .info-content + .info-content {
      margin-top: 100px; } }

.info-content-dlbtn {
  max-width: 620px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .info-content-dlbtn .dlbtn-box {
    width: 100%;
    background: linear-gradient(135deg, #E6140A 0, #8c0005 100%);
    margin-top: 20px; }
    .info-content-dlbtn .dlbtn-box a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 60px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0.1em;
      text-align: center;
      line-height: 1.5;
      position: relative;
      padding: 12px 50px; }
  @media screen and (min-width: 768px) {
    .info-content-dlbtn .dlbtn-box.half {
      width: 48.4%; }
      .info-content-dlbtn .dlbtn-box.half:nth-of-type(even) {
        margin-left: 3.2%; } }
  .info-content-dlbtn .icon-pdf::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 20px;
    width: 17.5px;
    height: 20px;
    background: url(../img/common/icon_pdf_white.png) center center/contain no-repeat; }
  @media screen and (max-width: 767px) {
    .info-content-dlbtn .dlbtn-box {
      margin-top: 15px; }
      .info-content-dlbtn .dlbtn-box a {
        font-size: 12px;
        padding: 12px 40px; }
    .info-content-dlbtn .dlbtn-box.half {
      width: 100%; }
    .info-content-dlbtn .icon-pdf::after {
      right: 15px; } }

/**
* 国家資格 国際ライセンス
**/
.license-content .page-text-tit-03 {
  margin-top: 60px; }
  @media screen and (max-width: 767px) {
    .license-content .page-text-tit-03 {
      margin-top: 50px; } }

.license-content-support {
  margin-top: 100px; }
  .license-content-support .sub {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 13px; }
  .license-content-support .page-unbar-title {
    margin-top: 0; }
  @media screen and (max-width: 767px) {
    .license-content-support {
      margin-top: 25px; }
      .license-content-support .sub {
        font-size: 15px;
        margin-top: 15px;
        margin-bottom: 10px;
        line-height: 1.6667; }
      .license-content-support .flex-item + .flex-item {
        margin-top: 25px; } }

.license-content-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -10px;
  margin-top: 30px; }
  .license-content-list + .license-content-list {
    margin-top: 40px; }
  .license-content-list .item {
    position: relative; }
    @media screen and (min-width: 768px) {
      .license-content-list .item + .item {
        margin-left: 20px; } }
  .license-content-list .circle {
    color: #fff;
    font-size: 25px;
    width: 40px;
    height: 40px;
    background: #E6140A;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box; }
    @media screen and (min-width: 768px) {
      .license-content-list .circle {
        border: 10px solid #fff;
        position: absolute;
        top: -10px;
        left: 0; } }
  .license-content-list .box {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 1.3889;
    margin-left: 30px;
    letter-spacing: 0.05em; }
    @media screen and (min-width: 768px) {
      .license-content-list .box {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #E6140A;
        height: 100px; }
        .license-content-list .box.col3 {
          width: 260px; }
        .license-content-list .box.col2 {
          width: 400px; } }
  @media screen and (max-width: 767px) {
    .license-content-list {
      display: block;
      margin-top: 25px; }
      .license-content-list + .license-content-list {
        margin-top: 0px; }
      .license-content-list .item {
        display: flex;
        align-items: center;
        border-bottom: 1px dotted #E6140A;
        padding-bottom: 18px;
        margin-bottom: 16px; }
      .license-content-list .box {
        font-size: 15px;
        line-height: 1.3889;
        text-align: justify;
        margin-left: 10px; } }

.license-content-step {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E6140A;
  padding-bottom: 50px; }
  .license-content-step li {
    width: 280px; }
  .license-content-step .upper {
    display: flex;
    align-items: center;
    border-bottom: 1px dotted #E6140A;
    padding-bottom: 11px;
    margin-bottom: 10px;
    min-height: 87px; }
    .license-content-step .upper .inr {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .license-content-step .upper .step {
      color: #E6140A;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0.02em;
      text-align: center; }
    .license-content-step .upper .circle {
      color: #fff;
      font-size: 25px;
      width: 40px;
      height: 40px;
      background: #E6140A;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3px; }
    .license-content-step .upper .tit {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.3889;
      margin-left: 11px;
      letter-spacing: 0.05em; }
  @media screen and (max-width: 767px) {
    .license-content-step {
      display: block;
      margin-top: 65px; }
      .license-content-step li {
        width: 100%; }
        .license-content-step li + li {
          margin-top: 30px; }
      .license-content-step .upper {
        padding-bottom: 9px;
        margin-bottom: 6px;
        min-height: auto; }
        .license-content-step .upper .step {
          font-size: 12px; }
        .license-content-step .upper .circle {
          font-size: 22px;
          width: 35px;
          height: 35px;
          margin-top: 2px; }
        .license-content-step .upper .tit {
          font-size: 15px;
          margin-top: 12px; } }

.license-content-news {
  margin-top: 135px; }
  .license-content-news .upper {
    background: #E6140A;
    padding: 40px 40px 25px;
    position: relative; }
    .license-content-news .upper .news {
      position: absolute;
      top: -19px;
      left: 38px;
      min-width: 150px;
      margin: 0 auto;
      padding: 7px;
      border: 2px solid #e6140a;
      color: #e6140a;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      letter-spacing: .05em;
      background: #fff; }
    .license-content-news .upper .tit {
      color: #fff;
      font-size: 25px;
      font-weight: bold;
      letter-spacing: 0.05em; }
    .license-content-news .upper .lead {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.05em;
      margin-top: 15px;
      margin-bottom: 13px; }
    .license-content-news .upper .text {
      color: #fff;
      font-size: 14px;
      line-height: 1.7143;
      letter-spacing: 0.05em;
      text-align: justify; }
  .license-content-news .box {
    border: 10px solid #E6140A;
    padding: 30px; }
    .license-content-news .box .tit {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.05em; }
    .license-content-news .box .inr {
      font-size: 13px;
      line-height: 1.9231;
      margin-top: 15px;
      letter-spacing: 0.05em; }
      .license-content-news .box .inr p {
        margin-top: 7px; }
  @media screen and (max-width: 767px) {
    .license-content-news {
      margin-top: 95px; }
      .license-content-news .upper {
        padding: 30px 21px 15px; }
        .license-content-news .upper .news {
          font-size: 17px;
          top: -19px;
          left: 0;
          right: 0;
          margin: 0 auto; }
        .license-content-news .upper .tit {
          font-size: 18px;
          text-align: center; }
        .license-content-news .upper .lead {
          font-size: 15px;
          text-align: center;
          line-height: 1.7333;
          margin-top: 10px;
          margin-bottom: 8px; }
        .license-content-news .upper .text {
          font-size: 13px; }
      .license-content-news .box {
        border: 5px solid #E6140A;
        padding: 25px 15px; }
        .license-content-news .box .tit {
          font-size: 15px;
          text-align: left; }
        .license-content-news .box .inr {
          line-height: 1.6923; }
          .license-content-news .box .inr p {
            margin-top: 12px; } }

/**
* このサイトについて
**/
.privacy-mid-tit {
  font-size: 15px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 8px;
  letter-spacing: 0.05em; }

/**
* 1・2年生のみなさんへ
**/
.student-content .page-btn-column {
  margin-top: 50px; }

/**
* オープンキャンパス
**/
.Opencampus .page-btn-center {
  margin-top: 50px; }

.Opencampus .opencampus-content-top .page-content-heading {
  margin-top: 0; }

.opencampus-content-top {
  padding-top: 70px; }
  .opencampus-content-top .card {
    padding-top: 60px;
    margin-top: -60px; }
  .opencampus-content-top .card + .card {
    margin-top: 0px; }
    .opencampus-content-top .card + .card .opencampus-date-list {
      border-top: 1px dotted #999;
      padding-top: 50px; }

.opencampus-date-list {
  margin: 0 auto;
  font-size: 15px;
  line-height: 1.7;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px 0; }
  .opencampus-date-list li {
    padding: 0 10px;
    text-align: center; }

.opencampus-content-special {
  margin-top: 50px;
  padding-top: 70px; }
  @media screen and (max-width: 767px) {
    .opencampus-content-special {
      margin-top: 0px; } }

.opencampus-content-go {
  margin-top: 50px;
  padding-top: 70px; }
  .opencampus-content-go .page-text-tit-02 {
    margin-top: 30px; }
  @media screen and (max-width: 767px) {
    .opencampus-content-go {
      margin-top: 0px; } }

.opencampus-check-list {
  width: 700px;
  margin: 60px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .opencampus-check-list li {
    position: relative;
    width: 320px; }
    .opencampus-check-list li:nth-of-type(n+3) {
      margin-top: 60px; }
  .opencampus-check-list .check {
    position: absolute;
    top: -19px;
    left: 0;
    right: 0;
    width: 150px;
    margin: 0 auto;
    padding: 9px 20px;
    border: 2px solid #e6140a;
    color: #e6140a;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .05em;
    background: #fff; }
  .opencampus-check-list .image {
    max-height: 220px;
    overflow: hidden; }
    .opencampus-check-list .image img {
      width: 100%; }
  .opencampus-check-list .tit {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
    margin: 12px 0 8px;
    letter-spacing: .05em; }
  .opencampus-check-list .text {
    font-size: 13px;
    line-height: 1.9231;
    letter-spacing: .05em;
    text-align: justify; }
  @media screen and (max-width: 767px) {
    .opencampus-check-list {
      width: 100%;
      margin-top: 0; }
      .opencampus-check-list li {
        width: 48.5%; } }
  @media screen and (max-width: 499px) {
    .opencampus-check-list {
      width: 100%; }
      .opencampus-check-list li {
        width: 100%;
        margin-top: 60px; } }

/**
* BISEN Tube
**/
.bisentube-video-list {
  margin-top: 30px; }
  .bisentube-video-list .flex-item:nth-of-type(n+3) {
    margin-top: 50px; }
  .bisentube-video-list .text {
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: 0.05em;
    margin-top: 12px; }

/**
* 施設・設備
**/
.Facility .page-text-tit-02 {
  margin-top: 20px; }
  @media screen and (max-width: 767px) {
    .Facility .page-text-tit-02 {
      margin-top: 15px; } }

.Facility .page-text-tit-02-under {
  text-align: center;
  margin-top: 5px; }
  @media screen and (max-width: 767px) {
    .Facility .page-text-tit-02-under {
      text-align: justify;
      margin-top: 0px; } }

.facility-content {
  margin-top: 80px; }
  @media screen and (max-width: 767px) {
    .facility-content {
      margin-top: 65px; } }
  .facility-content .image01 {
    display: block; }
  .facility-content .image02 {
    display: block;
    margin-top: 100px; }
    @media screen and (max-width: 767px) {
      .facility-content .image02 {
        margin-top: 75px; } }

.Sitemap .page-content-heading, .Privacy .page-content-heading, .Info .page-content-heading, .Sitepolicy .page-content-heading {
  margin-top: 0; }

@media screen and (min-width: 768px) {
  .text-center-pc {
    text-align: center; }
  .u-d-flex {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5%; }
  .flex-item.col2 {
    width: 50%;
    padding-left: 5%; } }

.page-mainvisual {
  background: url(../img/common/bg_mainvisual.jpg) no-repeat center center/cover;
  padding: 142px 0 203px; }
  @media screen and (max-width: 767px) {
    .page-mainvisual {
      padding: 40px 0 45px;
      margin-top: 60px; } }

.page-Content-block {
  margin-top: -140px; }
  @media screen and (max-width: 767px) {
    .page-Content-block {
      margin-top: 0px; } }

.page-content-area {
  padding: 130px 0;
  background: #fff; }
  @media screen and (max-width: 767px) {
    .page-content-area {
      padding: 0px 0; } }

/**
* ページヘッドタイトル（ページ最上部タイトル）
**/
.page-head-tit {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  position: relative;
  line-height: 1.76;
  padding-bottom: 11px;
  letter-spacing: 0.15em; }
  .page-head-tit::after {
    content: "";
    display: block;
    width: 30px;
    height: 3px;
    background: #E6140A;
    background: linear-gradient(90deg, #E6140A 0%, #8c0005 100%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
  @media screen and (max-width: 767px) {
    .page-head-tit {
      font-size: 2.0rem;
      padding-bottom: 5px; }
      .page-head-tit::after {
        width: 20px;
        height: 2px; } }

/**
* コンテンツタイトル（30×1pxの赤下線）
**/
.page-content-heading {
  /*margin-top: 100px;*/
  /*共通にはできない*/ }
  .page-content-heading .box {
    font-size: 15px;
    font-weight: 500;
    background: #fff;
    padding: 7px;
    text-align: center;
    width: 150px;
    margin: 0 auto;
    letter-spacing: 0.05em; }
    .page-content-heading .box.red {
      padding: 9px;
      color: #E6140A;
      border: 1px solid #E6140A; }
  .page-content-heading .tit {
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.05em;
    position: relative;
    padding-bottom: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
    line-height: 1.5; }
    .page-content-heading .tit::after {
      content: '';
      width: 30px;
      height: 3px;
      background: #E6140A;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto; }
    .page-content-heading .tit .min {
      font-size: 16px;
      font-weight: normal;
      padding-left: 3px; }
    .page-content-heading .tit.brand {
      color: #E6140A; }
  .page-content-heading .tit-under {
    margin-top: 30px; }
  .page-content-heading .lead {
    text-align: center;
    margin-top: 13px; }
  .page-content-heading .u-d-flex p + ul + p {
    margin-top: 5px; }
  @media screen and (max-width: 767px) {
    .page-content-heading {
      margin-top: 70px; }
      .page-content-heading .tit {
        font-size: 20px;
        line-height: 1.5;
        padding-bottom: 12px; }
        .page-content-heading .tit::after {
          width: 20px;
          height: 2px; }
      .page-content-heading .lead {
        text-align: justify; } }

/**
* 35px装飾なしタイトル
**/
.page-text-tit-01 {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 1.8;
  text-align: center;
  letter-spacing: 0.15em;
  padding-left: 0.15em; }
  @media screen and (max-width: 767px) {
    .page-text-tit-01 {
      font-size: 2.0rem;
      line-height: 1.7;
      letter-spacing: 0.075em;
      padding-left: 0.075em; } }
  .page-text-tit-01.brand {
    color: #E6140A; }

.page-text-tit-01-under {
  margin-top: 20px; }

/**
* 23px装飾なしタイトル
**/
.page-text-tit-02 {
  font-size: 2.3rem;
  font-weight: bold;
  line-height: 1.8;
  text-align: center;
  letter-spacing: 0.15em;
  padding-left: 0.15em;
  margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .page-text-tit-02 {
      font-size: 1.6rem;
      line-height: 1.65;
      letter-spacing: 0.075em;
      padding-left: 0.075em; } }

.page-text-tit-02-under {
  margin-top: 15px; }

/**
* 18px装飾なしタイトル
**/
.page-text-tit-03 {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.8;
  text-align: center;
  letter-spacing: 0.15em;
  padding-left: 0.15em;
  margin-bottom: 10px; }
  .page-text-tit-03.brand {
    color: #E6140A; }
  @media screen and (max-width: 767px) {
    .page-text-tit-03 {
      font-size: 1.6rem;
      letter-spacing: 0.075em;
      padding-left: 0.075em;
      line-height: 1.65; } }

.page-text-tit-03-under {
  margin-top: 10px; }

/**
* 下線タイトル
**/
.page-unbar-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  padding-bottom: 15px;
  position: relative;
  margin-bottom: 13px;
  margin-top: 90px;
  letter-spacing: 0.05em; }
  @media screen and (max-width: 767px) {
    .page-unbar-title {
      margin-top: 50px;
      line-height: 1.4;
      padding-bottom: 11px; } }
  .page-unbar-title::before {
    content: '';
    width: 100%;
    height: 1px;
    background: #c8c8c8;
    position: absolute;
    bottom: 0;
    left: 0; }
  .page-unbar-title::after {
    content: '';
    width: 200px;
    height: 1px;
    background: #E6140A;
    position: absolute;
    bottom: 0;
    left: 0; }
    @media screen and (max-width: 767px) {
      .page-unbar-title::after {
        width: 50px; } }

/**
* 左線タイトル
**/
.page-lbar-tit {
  font-size: 20px;
  font-weight: bold;
  padding: 5px 0 5px 19px;
  position: relative;
  letter-spacing: 0.05em;
  margin-top: 50px;
  /*デフォルト*/
  margin-bottom: 12px;
  /*デフォルト*/ }
  .page-lbar-tit::before {
    content: '';
    width: 5px;
    height: 30px;
    background: #E6140A;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0; }

/**
 * 文字白 ブランド背景 タイトル 左詰め
 **/
.brand-box-title {
  display: inline-block;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.1em;
  padding: 8px 25px;
  background-color: #E6140A; }
  .brand-box-title span {
    margin-left: -5px; }
  @media screen and (max-width: 499px) {
    .brand-box-title {
      font-size: 12px;
      padding: 8px 15px; } }

/**
* 下線タイトル AER用
**/
.red-unline-title {
  color: #E6140A;
  font-size: 2.3rem;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  letter-spacing: 0.075em;
  margin-bottom: 10px;
  border-bottom: 1px solid #E6140A;
  padding-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .red-unline-title {
      font-size: 1.8rem; } }

/**
* スマホ時swipeアイコン
**/
.icon-swipe-sp {
  width: 246px;
  margin: 0 auto 13px; }
  @media screen and (min-width: 768px) {
    .icon-swipe-sp {
      display: none; } }

/**
* 表形式
<div class="page-row-box">
    <div class="page-row-head">学校名</dt>
    <div class="page-row-list">専門学校金沢美専</dd>
</div>
**/
.page-row-box {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  border-bottom: 1px solid #C8C8C8;
  display: flex;
  padding-left: 10px;
  padding-right: 10px; }
  .page-row-box::after {
    content: "";
    width: 200px;
    height: 1px;
    background-color: #E6140A;
    position: absolute;
    left: 0;
    bottom: -1px; }
  .page-row-box .page-row-head {
    color: #E6140A;
    font-weight: bold;
    line-height: 1.6666;
    width: 220px; }
  .page-row-box .page-row-list {
    line-height: 1.7; }
    .page-row-box .page-row-list a + a {
      margin-left: 15px; }
    .page-row-box .page-row-list li + li {
      margin-top: 5px; }
    .page-row-box .page-row-list li .spacer {
      margin-left: 1.4em; }

@media screen and (max-width: 499px) {
  .page-row-box {
    display: block; }
    .page-row-box .page-row-head {
      width: 100%;
      margin-bottom: 5px; } }

/**
* link-text
**/
a.arrow-r-brand {
  position: relative;
  padding-left: 0.85em;
  transition: 0.1s; }
  a.arrow-r-brand::before {
    content: '';
    background: url("../img/common/arrow-r-brand.svg") left center/contain no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 5px;
    height: 9px; }
  @media screen and (min-width: 768px) {
    a.arrow-r-brand:hover {
      color: #E6140A; } }

a.arrow-un-brand {
  position: relative;
  padding-left: 1.25em; }
  a.arrow-un-brand::before {
    content: '';
    background: url("../img/common/arrow-un-brand.svg") left center/contain no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 9px;
    height: 5px; }

a.target-r-bland {
  position: relative;
  padding-right: 1.1em; }
  a.target-r-bland::after {
    content: '';
    background: url("../img/common/icon_target_brand.svg") left center/contain no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 12px;
    height: 9px; }

/**
* ぺージナビ
**/
.pnav {
  display: flex;
  width: 100%;
  max-width: 900px;
  margin: 0 auto; }
  .pnav.head {
    margin-bottom: 100px; }
    .pnav.head.u-display-md {
      margin-bottom: 0; }
  .pnav.foot {
    margin-top: 115px; }
    @media screen and (min-width: 768px) {
      .pnav.foot {
        padding-top: 100px;
        border-top: 1px solid #b3b3b3; } }
  .pnav-item {
    width: 100%;
    border-right: 1px solid #b3b3b3;
    text-align: center;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1.3; }
    .pnav-item span.txt-course {
      display: block;
      font-size: 85%;
      position: relative; }
    @media screen and (min-width: 768px) {
      .pnav-item {
        display: flex;
        justify-content: center;
        align-items: center; }
        .pnav-item a:hover {
          opacity: 0.6; } }
    .pnav-item:nth-last-child(1) {
      border-right: 0; }
    .pnav-item a.current {
      color: #E6140A;
      pointer-events: none; }
  @media screen and (max-width: 767px) {
    .pnav {
      background: #e5e5e5;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      margin-bottom: 0; }
      .pnav-item {
        font-size: 13px;
        height: 45px;
        border-right: 1px solid #fff;
        letter-spacing: 0.05em; }
        .pnav-item a {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-flow: column; }
        .pnav-item a.current {
          color: #000;
          background: #fad0ce; } }
  @media screen and (max-width: 499px) {
    .pnav {
      flex-wrap: wrap; }
      .pnav-item {
        width: 50%; }
        .pnav-item.odd {
          width: 100%;
          border-top: 1px solid #fff; }
        .pnav-item:nth-child(even) {
          border-right: 0; } }

.selectNav .pnav-item span {
  cursor: pointer; }

.selectNav .pnav-item.select span {
  color: #E6140A;
  pointer-events: none; }

@media screen and (max-width: 767px) {
  .selectNav .pnav-item span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column; }
  .selectNav .pnav-item.select span {
    color: #000;
    background: #fad0ce; } }

.u-d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

/**
* 学校長　野沢 道生
**/
.principal-content .page-content-heading {
  margin-top: 0; }

.principal-content-profile {
  position: relative;
  padding-bottom: 20px;
  margin-top: 50px; }
  .principal-content-profile .box {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 328px;
    height: auto;
    padding: 27px 30px 30px;
    color: #fff;
    background-color: #E6140A;
    background-image: url(../img/common/bg_st_white30.png);
    background-size: 5px; }
    .principal-content-profile .box .tit {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.06em; }
    .principal-content-profile .box .name {
      margin-top: 8px;
      font-size: 23px;
      font-weight: bold;
      letter-spacing: 0.1em; }
      .principal-content-profile .box .name span {
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 0.07em;
        margin-left: 10px; }
    .principal-content-profile .box .post {
      font-size: 13px;
      margin-top: 11px;
      letter-spacing: 0.05em; }
    .principal-content-profile .box .text {
      font-size: 13px;
      line-height: 1.9;
      text-align: justify;
      margin-top: 15px;
      letter-spacing: 0.05em; }
  @media screen and (max-width: 767px) {
    .principal-content-profile {
      margin-top: 30px; }
      .principal-content-profile .box {
        position: static;
        width: 100%;
        padding: 25px 20px 30px; }
        .principal-content-profile .box .name {
          font-size: 18px; } }

.principal-content-one {
  margin-top: 100px; }
  @media screen and (max-width: 767px) {
    .principal-content-one {
      margin-top: 70px; } }

.principal-content-show {
  position: relative;
  padding-top: 40px;
  margin-top: 30px; }
  .principal-content-show .box {
    position: absolute;
    left: 170px;
    top: 0;
    width: 300px;
    height: auto;
    padding: 20px 20px 20px;
    color: #fff;
    background-color: #E6140A;
    background-image: url(../img/common/bg_st_white30.png);
    background-size: 5px; }
    .principal-content-show .box .lead {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.05em;
      line-height: 1.5;
      text-align: center; }
    .principal-content-show .box .text {
      font-size: 12px;
      line-height: 1.6667;
      text-align: justify;
      margin-top: 8px;
      letter-spacing: 0.05em; }
  .principal-content-show .tit {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 215px;
    height: 44px;
    color: #E6140A;
    font-size: 20px;
    font-weight: bold;
    line-height: 44px;
    text-align: center;
    background-color: #fff; }
  .principal-content-show .image {
    display: flex; }
  @media screen and (max-width: 767px) {
    .principal-content-show {
      padding-top: 0px; }
      .principal-content-show .box {
        position: static;
        width: 100%;
        padding: 20px 20px 17px; }
      .principal-content-show .tit {
        font-size: 16px;
        width: 180px;
        height: 34px;
        line-height: 34px; } }

.principal-content-seminar {
  position: relative;
  margin-top: 40px;
  padding-bottom: 40px; }
  .principal-content-seminar .box {
    position: absolute;
    right: 110px;
    bottom: 0;
    width: 366px;
    height: auto;
    padding: 20px 20px 20px;
    color: #fff;
    background-color: #E6140A;
    background-image: url(../img/common/bg_st_white30.png);
    background-size: 5px; }
    .principal-content-seminar .box .lead {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.05em;
      line-height: 1.667;
      text-align: center; }
    .principal-content-seminar .box .text {
      font-size: 12px;
      line-height: 1.6667;
      text-align: justify;
      margin-top: 8px;
      letter-spacing: 0.05em; }
  .principal-content-seminar .tit {
    position: absolute;
    top: 0;
    right: 200px;
    width: 270px;
    height: 44px;
    color: #E6140A;
    font-size: 20px;
    font-weight: bold;
    line-height: 44px;
    text-align: center;
    background-color: #fff; }
  .principal-content-seminar .image {
    display: flex; }
  @media screen and (max-width: 767px) {
    .principal-content-seminar {
      margin-top: 20px;
      padding-bottom: 0px; }
      .principal-content-seminar .box {
        position: static;
        width: 100%;
        padding: 20px 20px 17px; }
      .principal-content-seminar .tit {
        top: auto;
        right: 0px;
        bottom: 0;
        font-size: 16px;
        width: 200px;
        height: 34px;
        line-height: 34px; } }

.principal-content-message {
  margin-top: 100px; }
  .principal-content-message .principal-video {
    max-width: 600px;
    margin: 50px auto 0; }
  .principal-content-message .page-btn-center {
    margin-top: 50px; }
  @media screen and (max-width: 767px) {
    .principal-content-message {
      margin-top: 70px; } }

/**
* 学科紹介
**/
body#subject main {
  background: #fff; }

body#subject #footer {
  background: #fff; }

.Subject .page-content-area {
  background: #fbe2ec;
  transition: 2s; }

.Subject .top-tit {
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.05em;
  position: relative;
  padding-bottom: 27px; }
  .Subject .top-tit::after {
    content: '';
    width: 70px;
    height: 2px;
    background: #E6140A;
    position: absolute;
    left: 0;
    bottom: 0; }
  .Subject .top-tit span {
    font-size: 20px;
    letter-spacing: 0.05em;
    padding-left: 5px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -5px; }
  @media screen and (max-width: 767px) {
    .Subject .top-tit {
      font-size: 25px;
      line-height: 1.4;
      text-align: center;
      padding-bottom: 15px; }
      .Subject .top-tit::after {
        width: 20px;
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto; }
      .Subject .top-tit span {
        display: block;
        font-size: 12px;
        padding-left: 0;
        top: 0; } }

.Subject .top-area {
  display: flex;
  position: relative;
  height: 413px; }
  .Subject .top-area .photo {
    max-width: 630px;
    height: 413px; }
    .Subject .top-area .photo img {
      height: 100%;
      width: 100%; }
  @media screen and (max-width: 767px) {
    .Subject .top-area {
      display: block;
      height: auto; }
      .Subject .top-area .photo {
        max-width: 100%;
        height: auto; } }
  .Subject .top-area .text-box {
    width: 630px;
    min-width: 530px;
    padding: 60px 52px 40px;
    background: #fdf3f7; }
    .Subject .top-area .text-box .inr {
      position: relative;
      width: 100%;
      height: 100%; }
      @media screen and (min-width: 768px) {
        .Subject .top-area .text-box .inr.selected {
          display: flex;
          flex-flow: column;
          justify-content: center;
          margin-top: -12px; } }
    @media screen and (max-width: 767px) {
      .Subject .top-area .text-box {
        width: 100%;
        min-width: auto;
        padding: 25px 30px 30px 30px; } }
  .Subject .top-area .ann-box {
    display: flex;
    margin-top: 28px; }
    .Subject .top-area .ann-box .item {
      font-size: 15px;
      text-align: center;
      width: 140px;
      line-height: 1;
      padding: 9px 5px;
      border: 1px solid #909090;
      background: #fff;
      letter-spacing: 0.05em; }
      .Subject .top-area .ann-box .item + .item {
        margin-left: 10px; }
    @media screen and (max-width: 767px) {
      .Subject .top-area .ann-box {
        justify-content: center;
        margin-top: 20px; }
        .Subject .top-area .ann-box .item {
          font-size: 14px;
          padding: 8px 10px; } }
  .Subject .top-area .course-selected {
    font-size: 25px;
    font-weight: bold;
    color: #E6140A;
    letter-spacing: 0.05em;
    margin-top: 30px; }
    @media screen and (max-width: 767px) {
      .Subject .top-area .course-selected {
        font-size: 22px;
        text-align: center;
        margin-top: 25px; } }
  .Subject .top-area .course-select {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    /*p {
                border-bottom: 1px solid #909090;
                padding-bottom: 10px;
                margin-bottom: 5px;
                padding-left: 5px;
                letter-spacing: 0.05em;
            }*/ }
    .Subject .top-area .course-select ul li {
      padding-left: 5px;
      line-height: 1.8; }
      .Subject .top-area .course-select ul li a {
        font-size: 18px;
        font-weight: bold;
        padding-left: 15px;
        letter-spacing: 0.05em;
        background: url(../img/common/arrow-r-brand.svg) left center/7px 13px no-repeat; }
    @media screen and (max-width: 767px) {
      .Subject .top-area .course-select {
        position: static;
        margin-top: 25px;
        display: flex;
        justify-content: center; }
        .Subject .top-area .course-select p {
          margin-bottom: 8px; }
        .Subject .top-area .course-select ul {
          display: block; }
          .Subject .top-area .course-select ul li a {
            font-size: 14px;
            padding-left: 12px;
            background-size: 5px 9px; } }
  .Subject .top-area .band-tips {
    color: #fff;
    font-size: 15px;
    line-height: 1;
    letter-spacing: 0.1em;
    padding: 10px 5px;
    width: 350px;
    text-align: center;
    background: #E6140A;
    margin-bottom: 25px; }
    @media screen and (max-width: 767px) {
      .Subject .top-area .band-tips {
        font-size: 14px;
        width: 100%;
        max-width: 320px;
        margin: 0 auto 15px;
        padding: 8px 5px; } }

@media screen and (max-width: 767px) {
  .Subject .page-content-area {
    padding-top: 50px; } }

.Subject .page-btn-column {
  margin-top: 50px; }

.Subject .page-btn-center {
  margin-top: 30px; }

.Subject .page-unbar-title {
  margin-top: 70px; }
  @media screen and (max-width: 767px) {
    .Subject .page-unbar-title {
      margin-top: 40px; } }

@media screen and (max-width: 767px) {
  .Subject .page-content-heading {
    margin-top: 0; }
    .Subject .page-content-heading .u-d-flex-center {
      display: block; }
      .Subject .page-content-heading .u-d-flex-center .sub {
        display: block;
        line-height: 1;
        padding-bottom: 4px; } }

.subject-box-tit {
  font-size: 22px;
  font-weight: bold;
  border: 1px solid #606060;
  padding: 18px;
  width: 590px;
  margin: 95px auto 0;
  text-align: center;
  letter-spacing: 0.05em;
  background: #fff;
  position: relative; }
  .subject-box-tit::after {
    content: '';
    width: 1px;
    height: 14px;
    background: #606060;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -7px;
    margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .subject-box-tit {
      font-size: 16px;
      padding: 14px;
      margin: 55px auto 0;
      width: 100%;
      max-width: 335px; } }

.subject-number-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 75px;
  border-bottom: 2px solid #fff; }
  .subject-number-list li {
    width: 280px; }
    .subject-number-list li .upper {
      display: flex;
      align-items: center;
      margin-bottom: 15px; }
      .subject-number-list li .upper .number {
        font-size: 60px;
        color: #E6140A;
        width: 44px; }
      .subject-number-list li .upper .tit {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 0.05em;
        position: relative;
        top: 1px; }
  @media screen and (max-width: 767px) {
    .subject-number-list {
      margin-top: 30px; }
      .subject-number-list li {
        width: 100%; }
        .subject-number-list li .upper {
          margin-bottom: 5px; }
          .subject-number-list li .upper .number {
            font-size: 45px;
            color: #E6140A;
            width: 34px; }
          .subject-number-list li .upper .tit {
            font-size: 15px; }
        .subject-number-list li + li {
          margin-top: 30px; } }

.subject-content-principal {
  padding-top: 55px;
  padding-bottom: 100px;
  border-bottom: 2px solid #fff; }
  .subject-content-principal .subject-pri-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px; }
    .subject-content-principal .subject-pri-head .image {
      width: 260px; }
    .subject-content-principal .subject-pri-head .text-area {
      width: 595px; }
      .subject-content-principal .subject-pri-head .text-area .upper {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.05em; }
      .subject-content-principal .subject-pri-head .text-area .name {
        font-size: 23px;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-top: 33px; }
      .subject-content-principal .subject-pri-head .text-area .text {
        font-size: 13px;
        line-height: 1.92308;
        letter-spacing: 0.05em;
        margin-top: 25px;
        text-align: justify; }
      .subject-content-principal .subject-pri-head .text-area .text-link {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-top: 25px; }
        .subject-content-principal .subject-pri-head .text-area .text-link a {
          color: #E6140A; }
          @media screen and (min-width: 768px) {
            .subject-content-principal .subject-pri-head .text-area .text-link a:hover {
              opacity: 0.6; } }
    @media screen and (max-width: 767px) {
      .subject-content-principal .subject-pri-head {
        display: block; }
        .subject-content-principal .subject-pri-head .image {
          width: 280px;
          margin: 0 auto; }
        .subject-content-principal .subject-pri-head .text-area {
          width: 280px;
          margin: 15px auto 0; }
          .subject-content-principal .subject-pri-head .text-area .upper {
            font-size: 13px;
            text-align: center;
            line-height: 1.53846; }
          .subject-content-principal .subject-pri-head .text-area .name {
            font-size: 18px;
            text-align: center;
            margin-top: 18px; }
          .subject-content-principal .subject-pri-head .text-area .text {
            margin-top: 22px; }
          .subject-content-principal .subject-pri-head .text-area .text-link {
            margin-top: 18px; } }
  .subject-content-principal .subject-pri-foot {
    margin-top: 90px; }

.subject-content-top {
  padding-top: 110px; }
  .subject-content-top .image {
    width: 250px;
    margin: 40px auto 50px; }
  .subject-content-top .graph {
    width: 100%; }
  @media screen and (max-width: 767px) {
    .subject-content-top {
      padding-top: 70px; }
      .subject-content-top .graph-cover {
        width: 100%;
        overflow-x: scroll; }
      .subject-content-top .graph {
        width: 900px; } }

.subject-content-license {
  padding-top: 100px; }
  @media screen and (max-width: 767px) {
    .subject-content-license {
      padding-top: 90px; } }

.subject-content-admit {
  padding-top: 100px; }
  .subject-content-admit .logo-admit {
    width: 95px;
    margin: 0 auto 23px; }
  .subject-content-admit .logo-icam {
    width: 146px;
    margin: 40px auto 23px; }
  @media screen and (max-width: 767px) {
    .subject-content-admit {
      padding-top: 70px; } }

.subject-content-curriculum {
  padding-top: 100px; }
  .subject-content-curriculum .c-list-circle-white .name {
    font-weight: 500; }
  .subject-content-curriculum .c-list-circle-white .leader {
    letter-spacing: -0.4em;
    padding-right: 0.5em; }
  @media screen and (max-width: 767px) {
    .subject-content-curriculum .c-list-circle-white .name {
      display: block; }
    .subject-content-curriculum .c-list-circle-white .leader {
      display: none; } }
  .subject-content-curriculum .grade {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 5px;
    letter-spacing: 0.1em; }
  .subject-content-curriculum .line-t-txt {
    margin-top: 45px;
    border-top: 1px solid #c8c8c8;
    padding-top: 15px; }
  .subject-content-curriculum .text-tit {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: 2; }
  @media screen and (max-width: 767px) {
    .subject-content-curriculum {
      padding-top: 70px; }
      .subject-content-curriculum .line-t-txt {
        margin-top: 25px; }
      .subject-content-curriculum .text-tit {
        font-size: 14px; } }
  .subject-content-curriculum .mt-spacer {
    margin-top: 10px; }
  .subject-content-curriculum .mt-spacer-02 {
    margin-top: 20px; }

.subject-content-pickup {
  padding-top: 100px; }
  @media screen and (max-width: 767px) {
    .subject-content-pickup {
      padding-top: 70px; } }
  .subject-content-pickup .pickup-img {
    width: 280px;
    margin: 30px auto 0; }
    .subject-content-pickup .pickup-img.unlayer {
      width: 350px; }
  .subject-content-pickup .pickup-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px; }
    .subject-content-pickup .pickup-list li {
      margin-top: 50px;
      padding-left: 30px; }
      .subject-content-pickup .pickup-list li.col3 {
        width: 33.3333%; }
      .subject-content-pickup .pickup-list li.col2 {
        width: 45%; }
    .subject-content-pickup .pickup-list .name {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.05em;
      padding-bottom: 13px;
      margin-bottom: 9px;
      border-bottom: 1px solid #aaa; }
    @media screen and (min-width: 768px) {
      .subject-content-pickup .pickup-list.beauty, .subject-content-pickup .pickup-list.unlayer {
        justify-content: center;
        width: 610px;
        margin: 0 auto; }
        .subject-content-pickup .pickup-list.beauty li, .subject-content-pickup .pickup-list.unlayer li {
          width: 280px;
          padding-left: 0px; }
        .subject-content-pickup .pickup-list.beauty li:nth-child(even), .subject-content-pickup .pickup-list.unlayer li:nth-child(even) {
          margin-left: 50px; } }
    @media screen and (max-width: 767px) {
      .subject-content-pickup .pickup-list li {
        margin-top: 30px; }
        .subject-content-pickup .pickup-list li.col3 {
          width: 100%; }
        .subject-content-pickup .pickup-list li.col2 {
          width: 100%; }
      .subject-content-pickup .pickup-list .name {
        font-size: 16px; } }

.subject-content-linkbtn {
  padding-top: 100px; }
  @media screen and (max-width: 767px) {
    .subject-content-linkbtn {
      padding-top: 70px; } }

/**
* 専攻
**/
.subject-content-reason {
  padding-top: 135px; }
  .subject-content-reason .upper {
    background: #E6140A;
    padding: 40px 40px 15px;
    position: relative; }
    .subject-content-reason .upper .reason {
      position: absolute;
      top: -19px;
      left: 38px;
      min-width: 150px;
      margin: 0 auto;
      padding: 9px 20px;
      border: 2px solid #e6140a;
      color: #e6140a;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      letter-spacing: .05em;
      background: #fff; }
    .subject-content-reason .upper .lead {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.05em;
      line-height: 1.5; }
  .subject-content-reason .box {
    border: 10px solid #E6140A;
    padding: 25px 30px;
    position: relative;
    background: #fff; }
    .subject-content-reason .box .inr {
      max-width: 570px;
      font-size: 13px;
      line-height: 1.9231;
      letter-spacing: 0.05em; }
      .subject-content-reason .box .inr .name {
        margin-top: 10px; }
        @media screen and (min-width: 768px) {
          .subject-content-reason .box .inr .name.mt-spacer-01 {
            margin-top: 30px; }
          .subject-content-reason .box .inr .name.mt-spacer-02 {
            margin-top: 50px; } }
      .subject-content-reason .box .inr .image {
        width: 220px; }
        @media screen and (min-width: 768px) {
          .subject-content-reason .box .inr .image {
            position: absolute;
            bottom: 30px;
            right: 30px; } }
  @media screen and (max-width: 767px) {
    .subject-content-reason {
      padding-top: 95px; }
      .subject-content-reason .upper {
        padding: 28px 21px 15px; }
        .subject-content-reason .upper .reason {
          max-width: 300px;
          font-size: 16px;
          top: -19px;
          left: 0;
          right: 0;
          margin: 0 auto;
          padding: 8px 10px; }
        .subject-content-reason .upper .lead {
          font-size: 15px;
          text-align: center;
          line-height: 1.7333; }
      .subject-content-reason .box {
        border: 5px solid #E6140A;
        padding: 25px 15px; }
        .subject-content-reason .box .tit {
          font-size: 15px;
          text-align: left; }
        .subject-content-reason .box .inr {
          line-height: 1.6923; }
          .subject-content-reason .box .inr .name {
            text-align: center; }
          .subject-content-reason .box .inr .image {
            margin: 18px auto 0; } }

/**
* ワーキングスタディ
**/
.subject-content-graph .work-graph {
  display: block;
  margin-top: 30px; }
  @media screen and (max-width: 767px) {
    .subject-content-graph .work-graph {
      width: 320px;
      margin: 30px auto 0; } }

.subject-content-graph .work-graph-schedule-sp {
  width: 320px;
  margin: 30px auto 0; }
  .subject-content-graph .work-graph-schedule-sp li {
    border: 1px solid #E6140A;
    font-size: 11px;
    text-align: center;
    height: 45px;
    display: flex;
    align-items: center;
    background: #fff;
    margin-top: 20px;
    padding-left: 35px; }
    .subject-content-graph .work-graph-schedule-sp li span {
      color: #E6140A;
      font-size: 15px;
      font-weight: bold;
      padding-right: 10px;
      padding-left: 10px;
      letter-spacing: 0.05em; }
    .subject-content-graph .work-graph-schedule-sp li.d-center {
      justify-content: center;
      padding-left: 0; }
    .subject-content-graph .work-graph-schedule-sp li.icon-Acc {
      position: relative;
      cursor: pointer; }
      .subject-content-graph .work-graph-schedule-sp li.icon-Acc::before {
        content: '';
        width: 1px;
        height: 15px;
        background: #000;
        position: absolute;
        right: 7px;
        opacity: 1;
        transition: .4s;
        margin-right: 10px; }
      .subject-content-graph .work-graph-schedule-sp li.icon-Acc::after {
        content: '';
        width: 15px;
        height: 1px;
        background: #000;
        position: absolute;
        right: 0;
        margin-right: 10px; }
  .subject-content-graph .work-graph-schedule-sp li.Acc-trigger.Active.icon-Acc::before {
    transform: rotate(90deg);
    opacity: 0; }
  .subject-content-graph .work-graph-schedule-sp .Acc-target {
    display: none;
    border: 1px solid #E6140A;
    border-top: 0;
    background: #fff; }

.scla-head-area .upper {
  padding-left: .05em;
  font-size: 15px;
  text-align: center;
  letter-spacing: .05em; }
  @media screen and (max-width: 767px) {
    .scla-head-area .upper {
      font-size: 13px; } }

.scla-head-area .text-head {
  margin-top: 20px;
  display: block;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .scla-head-area .text-head {
      margin-top: 15px; } }

.support-content {
  margin-top: 110px; }
  @media screen and (max-width: 767px) {
    .support-content {
      margin-top: 60px; } }
  .support-content .page-unbar-title:nth-of-type(1) {
    margin-top: 80px; }
  .support-content .page-unbar-title {
    margin-top: 60px; }
  .support-content .c-list-asterisk {
    font-size: 12px;
    line-height: 2;
    letter-spacing: .1em;
    margin-top: 3px; }
  .support-content .brand-box-title {
    margin: 18px 0 12px; }
    .support-content .brand-box-title.second {
      margin-top: 35px; }
    @media screen and (max-width: 499px) {
      .support-content .brand-box-title {
        margin: 18px 0 8px; }
        .support-content .brand-box-title.second {
          margin-top: 30px; } }
  .support-content .colmun-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -50px; }
  .support-content .colmun {
    width: 50%;
    padding-left: 50px; }
    @media screen and (max-width: 499px) {
      .support-content .colmun {
        width: 100%; }
        .support-content .colmun + .colmun {
          margin-top: 20px; }
        .support-content .colmun + .colmun.second {
          margin-top: 0; } }
  .support-content .content-tit {
    margin-top: 10px;
    line-height: 2;
    letter-spacing: .05em; }
  .support-content .mt-spacer {
    line-height: 2;
    margin-top: 20px; }
    @media screen and (max-width: 499px) {
      .support-content .mt-spacer {
        margin-top: 5.33333vw; } }
  .support-content .fee {
    font-size: 18px;
    letter-spacing: 0.05em;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 30px; }
    .support-content .fee dt {
      font-weight: bold; }
    .support-content .fee dd {
      margin-top: 18px; }
    @media screen and (max-width: 499px) {
      .support-content .fee {
        font-size: 14px;
        margin-top: 25px;
        margin-bottom: 15px; }
        .support-content .fee dt {
          font-size: 15px; }
        .support-content .fee dd {
          margin-top: 10px;
          font-feature-settings: "palt" 1; } }
  .support-content .pay {
    font-size: 15px;
    letter-spacing: 0.1em;
    padding-left: 0.1em;
    text-align: center; }
    .support-content .pay b {
      font-size: 18px; }
    @media screen and (max-width: 499px) {
      .support-content .pay {
        font-size: 13px; }
        .support-content .pay b {
          font-size: 15px; } }

.scholarship_table {
  width: 100%;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.05em;
  margin-top: 10px;
  margin-bottom: 15px; }
  .scholarship_table th, .scholarship_table td {
    padding: 12px 25px;
    text-align: center;
    letter-spacing: 0.1em; }
  .scholarship_table th {
    color: #fff;
    font-weight: normal;
    background: #E6140A;
    border: 1px solid #E6140A; }
  .scholarship_table td {
    border: 1px solid #E6140A; }
  .scholarship_table.scholarship_table_01 th {
    border-right: 1px solid #fff; }
    .scholarship_table.scholarship_table_01 th:nth-child(3) {
      border-right: 1px solid #E6140A; }
  .scholarship_table.scholarship_table_02 th {
    border-bottom: 1px solid #fff; }
  .scholarship_table.scholarship_table_02 tr:nth-last-child(1) th {
    border-bottom: 1px solid #E6140A; }
  @media screen and (max-width: 499px) {
    .scholarship_table {
      font-size: 13px;
      line-height: 1.7;
      letter-spacing: 0.05em;
      margin-top: 10px;
      margin-bottom: 15px; }
      .scholarship_table th, .scholarship_table td {
        padding: 12px 10px;
        text-align: center; }
      .scholarship_table.scholarship_table_01 th:nth-child(1) {
        width: 110px; }
      .scholarship_table.scholarship_table_02 th, .scholarship_table.scholarship_table_02 td {
        padding: 10px 10px;
        text-align: center; } }

.scholarship-case_table {
  width: 100%;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.05em;
  margin-top: 10px;
  margin-bottom: 15px; }
  .scholarship-case_table th, .scholarship-case_table td {
    padding: 12px 25px;
    text-align: center;
    border: 1px solid #E6140A;
    letter-spacing: 0.1em; }
  .scholarship-case_table thead th {
    color: #fff;
    background: #E6140A; }
  .scholarship-case_table tbody th {
    color: #fff;
    background: url("../../assets/img/common/bg_st_white30.png") left top/5px 5px repeat;
    background-color: rgba(230, 20, 10, 0.9); }
  .scholarship-case_table tbody td {
    text-align: right; }
  .scholarship-case_table .total {
    font-weight: bold; }
  @media screen and (max-width: 499px) {
    .scholarship-case_table {
      font-size: 3.46667vw;
      line-height: 1.5;
      letter-spacing: 0.05em;
      margin-top: 1.33333vw;
      margin-bottom: 2.66667vw; }
      .scholarship-case_table th, .scholarship-case_table td {
        padding: 2.13333vw 2.66667vw;
        text-align: center; } }

.c-unbar-brand {
  border-bottom: 1px solid #E6140A; }

table.default-table {
  font-size: 1.02489vw;
  line-height: 2;
  letter-spacing: 0.05em; }
  table.default-table th {
    vertical-align: top; }
  @media screen and (max-width: 499px) {
    table.default-table {
      font-size: 3.2vw; } }

.example-table td.example {
  vertical-align: top; }

.support-download-content {
  padding-top: 60px; }
  @media screen and (max-width: 499px) {
    .support-download-content {
      padding-top: 40px; } }
  .support-download-content .lead {
    font-size: 16px;
    line-height: 2;
    letter-spacing: 0.15em;
    padding-left: 0.15em;
    font-weight: bold;
    text-align: center; }
    @media screen and (max-width: 499px) {
      .support-download-content .lead {
        font-size: 14px;
        line-height: 1.8;
        letter-spacing: 0.1em; } }
  .support-download-content .app-area {
    margin-top: 20px; }
    .support-download-content .app-area .item {
      text-align: center; }
      .support-download-content .app-area .item .image {
        width: 160px;
        margin: 7px auto 0; }
        .support-download-content .app-area .item .image img {
          width: 100%; }
      .support-download-content .app-area .item .text {
        letter-spacing: 0.15em; }
        .support-download-content .app-area .item .text img {
          width: 100%; }
      .support-download-content .app-area .item + .item {
        margin-top: 20px; }
    @media screen and (max-width: 499px) {
      .support-download-content .app-area {
        margin-top: 20px; }
        .support-download-content .app-area .item .image {
          width: 160px; }
          .support-download-content .app-area .item .image img {
            width: 100%; }
        .support-download-content .app-area .item .text {
          width: 100%;
          margin-left: 10px; }
        .support-download-content .app-area .item + .item {
          margin-top: 20px; } }
  .support-download-content .page-btn-column {
    margin-top: 25px;
    margin-bottom: 40px; }
  .support-download-content .page-btn-box {
    min-height: 55px; }
